import React from 'react';
import Header from './common/headers/Header.SQWAD';
import fakeAuth from '../fakeAuth';
import SQWADMenu from './menu/SQWADMenu';
import FamilyMenu from './menu/SQWAD_Familly_Menu';
import FamilyPicker from './FamilyPicker';

class Main extends React.Component {

  state = {
    menuHidden: false,
    section: null,
    picking_family: false
  }

  componentWillMount() {

    this.setState({
      section: this.props.route.path
    });

    if (fakeAuth.isAuthenticated()) {
      this.props.login &&
        this.props.login(fakeAuth.getCredentials());

      this.props.familyActions &&
        this.props.familyActions.fetchTeams();

      this.props.familyActions &&
        this.props.familyActions.fetchMembers();

    } else {
      fakeAuth.signout(() => {
        this.props.router.push('/login'); //from.pathname);
      });
    }

  }

  componentWillReceiveProps = nextProps => {
    this.setState({ section: nextProps.route.path, menuHidden: nextProps.route.path === '/sqwad' });
  }

  componentWillUnmount() {
    this.props.familyActions &&
      this.props.familyActions.clearTeams &&
      this.props.familyActions.clearTeams();
    this.props.teamActions && this.props.teamActions.clearSelectedTeam();
  }

  hideMenu = () => {
    const { menuHidden } = this.state;
    this.setState({ menuHidden: !menuHidden });
  }

  right = () => this.setState({ openRight: !this.state.openRight });

  render() {

    const { menuHidden, section, openRight } = this.state;

    return (

      <section className="">
        <Header {...this.props} onFamily={() => this.setState({ picking_family: !this.state.picking_family })} />
        <section id="main-content-6">
          {section === '/sqwad/team/:idTeam' && <SQWADMenu {...this.props} />}
          {section === '/sqwad/family' && <FamilyMenu {...this.props} />}
          <section id="content-wrapper" style={{ left: menuHidden ? 0 : '12em' }} className={`${menuHidden ? 'hidden' : ''} bg-white ${openRight ? 'open_right' : ''}`}>
            {React.cloneElement(this.props.children, this.props)}
          </section>
        </section>
        <FamilyPicker {...this.props} open={this.state.picking_family} toggle={() => this.setState({ picking_family: false })} />
      </section>

    );
  }
}

export default Main;

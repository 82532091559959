import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../redux-sagas/actions/actionCreators';
import * as leagueActions from '../redux-sagas/actions/leagueActions';
import * as seasonActions from '../redux-sagas/actions/seasonActions';
import * as familyActions from '../redux-sagas/actions/familyActions';
import * as pouchActions from '../redux-sagas/actions/pouchActions';
import * as divisionsActions from '../redux-sagas/actions/divisionsActions';
import * as cartActions from '../redux-sagas/actions/cartActions';
import Main from '../components/Main.Family';
import _ from 'lodash';

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn,
        auth: state.auth,
        autoPaySettings: state.autoPaySettings,
        ageGroups: state.ageGroups,
        cart: state.cart,
        communities: state.communities,
        divisions: state.divisions,
        disclaimer: state.disclaimer,
        family: state.family,
        fetchingData: state.fetchingData,
        familyRegistration: state.familyRegistration,
        isCartOpen: state.isCartOpen,
        leagues: state.leagues,
        league: state.league,
        paymentPlans: state.paymentPlans,
        pouch: state.pouch,
        questions: state.questions,
        roles: state.roles,
        registrations: state.registrations,
        seasons: state.seasons,
        selectedTeam: state.selectedTeam,
        selectedUser: state.selectedUser,
        title: state.title,
        teams: state.teams,
        archived_teams: state.archived_teams,
        teamRegistration: state.teamRegistration,
    }
}

function mapDispatchToProps(dispatch) {
    return _.extend({
        leagueActions: bindActionCreators(leagueActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch),
        familyActions: bindActionCreators(familyActions, dispatch),
        pouchActions: bindActionCreators(pouchActions, dispatch),
        divisionsActions: bindActionCreators(divisionsActions, dispatch),
        cartActions: bindActionCreators(cartActions, dispatch)
    }, bindActionCreators(actionCreators, dispatch))
}

const App_Registration = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App_Registration;

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import request from 'superagent';
import config from '../../../config';
import { Link } from 'react-router';
import { encrypt_user } from '../../../helpers';
import _ from 'lodash';

class PayingPlacebo extends React.Component {

    state = {
        step: 1,
        message: null,
        total: 0,
        firstTeam: null
    }

    componentWillMount() {
        const { cart } = this.props;
        this.setState({ total: this.props.total, firstTeam: cart ? _.find(this.props.cart.items, i => i.selectedTeam && !i.selectedSeason.IdPendingRegistration) : null });
    }

    componentDidMount() {

        const { signature, card, due, total, cart, discounts } = this.props;
        if (signature) {

            // FIX the cart to work with v5 api
            cart.items.forEach(item => {

                item.selectedRole = _.omit(item.selectedRole, 'role');
                item.user = item.selectedUser;
                item.season = item.selectedSeason;
                item.division = item.selectedDivision;
                item.team = item.selectedTeam;
                item.plan = item.selectedPlan;
                item.league = item.selectedLeague;
                (item.user || {}).role = item.selectedRole;
                item.role = item.selectedRole;

                item.questions.forEach(question => {

                    if (question.IdQuestionType === 4 || _.filter(question.Answers, a => a.checked).length >= 1) {
                        question.selectedAnswer = {
                            options: [..._.filter(question.Answers, a => a.checked)]
                        }
                    } else {
                        let p = { ...question };
                        if (question.answer) {
                            question.selectedAnswer = {
                                options: [{ ...p, AnswerText: question.answer.AnswerText, Amount: question.answer.Amount, ...p.answer }]
                            }
                        } else {
                            question.selectedAnswer = {
                                options: [{ ...p, AnswerText: 'N/A', Amount: 0 }]
                            }
                        }
                    }
                });

            });

            // Encrypt a copy of the card
            let card_copy = { ...card };
            encrypt_user(card_copy);
            //

            request.post(`${config.apiEndpoint}/api/v6/registration/pay?web=1`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({
                    signature, card: card_copy, cart: _.chain(cart.items).map(i => _.omit(i, 'selectedUser', 'selectedSeason', 'selectedDivision',
                        'selectedTeam', 'selectedPlan', 'selectedLeague', 'selectedRole')).value(), total, discounts
                })
                .then((data) => {
                    if (data.body.success) {
                        this.setState({ step: 2, message: data.body.transactionId });
                        // Remove that cart
                    } else {
                        this.setState({ step: 3, message: data.body.message });
                    }
                }, ({ response: { body } }) => {
                    this.setState({
                        step: 3,
                        message: body.error.message
                    });
                });

        } else {

            // Due            
            request.post(`${config.apiEndpoint}/api/v4/registration/due`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({
                    card: card,
                    due: due,
                    total: total
                })
                .then((data) => {
                    if (data.body.success) {
                        this.setState({
                            step: 2,
                            message: data.body.transactionId
                        }, function () {
                            const { league } = this.props;
                            this.props.cartActions && this.props.cartActions.clearLeagueCart(league.IdLeague);
                        });

                        // Remove that cart
                    } else {
                        this.setState({
                            step: 3,
                            message: data.body.message
                        })
                    }
                }, ({ response: { body } }) => {
                    this.setState({
                        step: 3,
                        message: body.error.message
                    });
                });
        }
    }

    clear = (e) => {
        e.stopPropagation && e.stopPropagation();
        const { league } = this.props;
        this.props.cartActions && this.props.cartActions.clearLeagueCart(league.IdLeague);
    }

    close = () => {
        const { league } = this.props;
        this.props.cartActions && this.props.cartActions.clearLeagueCart(league.IdLeague);
        this.props.fnOk && this.props.fnOk();
    }

    render() {
        const { isOpen, toggle } = this.props,
            { step, firstTeam } = this.state;

        return (
            <Modal isOpen={isOpen} keyboard={false} backdrop={'static'}>
                <ModalBody>
                    <div>
                        {step === 1 && <div className="jumbotron no-margin text-center" key="step1">
                            <i className="fa fa-credit-card bigest-font heart" />
                            <h1 className="margin-top-half">Processing, please wait...</h1>
                        </div>}
                        {step === 2 && <div onClick={this.close} className="jumbotron no-margin text-center bg-success" key="step1" style={{ position: 'relative' }}>
                            <i className="fa fa-times white font-16 hoverable pointer" style={{ position: 'absolute', top: 15, left: 15 }} />
                            <i className="fa fa-check white bigest-font" />
                            <h1 className="white">Registration successful</h1>
                            <h3 className="white font-8">{this.state.message}</h3>
                            {firstTeam && <section className="mt-4 d-flex flex-column white">
                                <h2>Congratulations, your team has been registered</h2>
                                <h3>"NOW Let's get it ROSTERED"</h3>
                                <div style={{ height: 50 }} />
                                <Link to={`/roster/wizard/${firstTeam.selectedTeam.IdTeam}/${firstTeam.selectedSeason.IdSeason}`} onClick={this.clear} className="shadow-box btn btn-lg btn-white black m-auto" style={{ width: 200, backgroundColor: 'white' }}>GO</Link>
                            </section>}

                        </div>}
                        {step === 3 && <div onClick={toggle} className="jumbotron no-margin text-center bg-danger" key="step2" style={{ position: 'relative' }}>
                            <i className="fa fa-times white font-16 hoverable pointer" style={{ position: 'absolute', top: 15, left: 15 }} onClick={toggle} />
                            <i className="fa fa-times white bigest-font" />
                            <h1 className="white">Registration Failed</h1>
                            <h3 className="white">{this.state.message}</h3>
                        </div>}
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default PayingPlacebo;
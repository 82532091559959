import React from 'react';

class SelectableUser extends React.Component {

    state = {
        user: null
    }

    componentWillMount() {
        this.setState({ user: this.props.user });
    }

    errorImage = () => {
        const { user } = this.state;
        user.UserImage = `/images/defaults/${user.IdGender === 2 ? 'female' : 'male'}.png`;
        this.setState({ user });
    }

    render() {
        const { user } = this.state;
        return (<figure className={`d-flex flex-row margin-top-half ${!user.Token ? 'bg-gray w-100' : null}`}>

            <div className="user-avatar-rounded cover align-self-centger" 
            style={{ background: ['url(', user.UserImage, '?full=80) no-repeat center center black'].join('') }} />

            <div className="d-flex flex-column align-self-center idented justify-content-start">
                <span className="font-14 no-margin no-padding ">{[user.NameFirst, user.NameLast].join(' ')}</span>
                {(user.IsLeagueAdmin || user.IsAdmin) && <span className="blue no-padding no-margin text-left">Club Admin</span>}
            </div>

            {!user.Token && <i className="fa ml-auto fa-exclamation-triangle red margin-right-half align-self-center" />}

            <img alt="" onError={this.errorImage} className="m-auto" src={(user.UserImage)} style={{ width: '1px', height: '1px', position: 'absolute' }} />
        </figure>)
    }
}

export default SelectableUser;
import React from 'react';
import '../common/style.css';
import moment from 'moment';
import CreateFamilyMember from '../members/CreateMember';
import CreateTeam from '../teams/CreateTeam';
import CartPopup from '../common/CartPopup';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import _ from 'lodash';
import PersonSelection from './modals/PersonSelection';
import TeamSelection from './modals/TeamSelection';

class Seasons extends React.Component {

    state = {
        bootstrapped: false,
        isTeamSelectionOpen: false,
        season: null,
        isCreatingFamilyMember: false,
        isCreatingTeam: false
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, league } = this.props;

        setTitle && setTitle({
            main: 'Registration',
            sub: league ? league.Name : null
        });

        this.props.seasonActions &&
            this.props.seasonActions.fetchSeasonsForRegistration(params.idLeague);

        this.props.leagueActions &&
            this.props.leagueActions.fetchTeamRegistration(params.idLeague);

        this.props.leagueActions &&
            this.props.leagueActions.fetchFamilyRegistration(params.idLeague);

        this.props.cartActions && this.props.cartActions.close();

        if (!league || !league.IdLeague) {
            this.props.leagueActions &&
                this.props.leagueActions.fetchLeague(params.idLeague);
            this.props.leagueActions && this.props.leagueActions.fetchCurrency(params.idLeague);
        }
    }

    componentDidMount() {
        this.setState({ bootstrapped: true });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.league !== this.props.league) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                main: 'Registration',
                sub: nextProps.league.Name || nextProps.league.LeagueName
            })
        }
    }
    //

    selectSeason = (season) => {
        this.setState({
            season: season,
            isTeamSelectionOpen: (season.SeasonType === 'Tournament' || season.SeasonType === 'Hybrid') ? true : false,
            isPlayerSelectionOpen: (season.SeasonType === 'Tournament' || season.SeasonType === 'Hybrid') ? false : true
        }, () => {
            this.props.seasonActions && this.props.seasonActions.fetchRoles(season.IdSeason || season.idSeason);
        });
    }

    toogleTeamSelection = () => this.setState({ isTeamSelectionOpen: !this.state.isTeamSelectionOpen });
    togglePlayerSelection = () => this.setState({ isPlayerSelectionOpen: !this.state.isPlayerSelectionOpen });

    setOnPouchAndGo = (args) => {

        this.props.pouchActions &&
            this.props.pouchActions.set(args);

        // Detect if registering a team or a player / coach
        if (args.selectedTeam) {
            this.props.router && this.props.router.push('/registration/team');
        } else {
            if (this.state.season.IdSeasonType === 4)
                this.props.router && this.props.router.push('/registration/camp');
            else this.props.router && this.props.router.push('/registration/user');
        }
    }

    addFamilyMember = () => this.setState({ isCreatingFamilyMember: !this.state.isCreatingFamilyMember });
    addTeam = () => this.setState({ isCreatingTeam: !this.state.isCreatingTeam, isTeamSelectionOpen: false });

    toggleCart = () => {
        this.props.cartActions &&
            this.props.cartActions.close();
    }

    render() {
        const { fetchingData, seasons, teams, league, family, cart, teamRegistration, familyRegistration, isCartOpen, roles } = this.props,
            { bootstrapped, isTeamSelectionOpen, isPlayerSelectionOpen, season, isCreatingFamilyMember, isCreatingTeam } = this.state;

        return (
            <section id="registration-seasons">
                {league && <div className="card shadow-box w-100 px-2 py-1 d-flex flex-row">
                    <Link to="/registration" className="btn btn-link align-self-center">
                        <span className="blue font-10">Change</span>
                    </Link>
                    <div className="align-self-center ml-auto" style={{ width: 50, height: 40, background: `url(${league.LeagueImage}) no-repeat center center`, backgroundSize: 'contain' }} />
                    <span className="mx-2 black align-self-center">{league && (league.LeagueName || league.Name)}</span>
                </div>}
                <div className="mt-4 w3-container" style={{ zIndex: 9 }}>
                    { /* Loader */}
                    {bootstrapped && fetchingData &&
                        <div className="d-flex justify-content-center centered-message">
                            <h1 className="black align-self-center block text-center" key="loader"><i className="fa fa-spin fa-cog" /></h1></div>}

                    { /* SEASONS */}
                    {bootstrapped && !fetchingData &&
                        <div className="w3-content">
                            <ul className="white text-center d-flex flex-wrap pb-4 justify-content-center m-0" key="list">
                                {_.orderBy(seasons || [], 'RegistrationEnd').map((season, i) => (

                                    <li className="tile card shadow-box mx-1 mt-2" style={{ width: 300, height: 150 }} key={i}>
                                        <Animated animationIn="bounceIn" animationInDelay={i * 50} className="w-100 h-100">
                                            <div onClick={() => this.selectSeason(season)} className="align-self-center justify-content-center d-flex flex-row w-100 h-100">

                                                <div style={{ width: 60 }} className=" h-100 pt-4">
                                                    <i className={`icon-${season.SeasonType.toLowerCase()} font-20 color-${season.SeasonType}`} />
                                                </div>

                                                <div className="w-100 d-flex flex-column justify-content-center h-100 ">
                                                    <span className="text-left gray mr-2">{season.SeasonType}</span>
                                                    <span className="text-left mt-2 black toBold line1 mr-2">{season.Name || season.LeagueName}</span>
                                                    <span className="text-left mt-2 gray font-8 mr-2">{[moment(season.DateStart).format('MMM DD, YYYY').toUpperCase(), moment(season.DateEnd).format('MMM DD, YYYY').toUpperCase()].join(' to ')}</span>
                                                </div>
                                            </div>
                                        </Animated>
                                    </li>
                                ))}
                            </ul>
                        </div>}

                    { /* No Seasons */}
                    {bootstrapped && !fetchingData && !seasons.length &&
                        <div className="d-flex justify-content-center centered-message" style={{ top: '3em' }}>
                            <h1 className="black align-self-center block text-center" key="none">There are no Seasons available for<br />Registration on {league && (league.LeagueName || league.Name)}</h1></div>}
                </div>

                {isPlayerSelectionOpen && <PersonSelection close={this.togglePlayerSelection} cart={cart}
                    league={league} season={season} family={family} fnAddFamilyMember={this.addFamilyMember} fnOk={this.setOnPouchAndGo}
                    familyRegistration={_.filter(familyRegistration, r => r.IdSeason === season.IdSeason)} roles={roles} />}

                {isTeamSelectionOpen && <TeamSelection roles={roles} cart={cart} league={league} season={season} teams={teams} family={family}
                    fnAddTeam={this.addTeam} close={this.toogleTeamSelection} fnOk={this.setOnPouchAndGo} teamRegistration={teamRegistration} />}

                {isCartOpen && <CartPopup {...this.props} toggle={this.toggleCart} fnOk={this.toggleCart} isOpen={this.props.isCartOpen} />}

                {isCreatingFamilyMember && <CreateFamilyMember {...this.props} isOpen={isCreatingFamilyMember} toggle={this.addFamilyMember} fnOk={this.togglePlayerSelection} />}

                {isCreatingTeam && <CreateTeam {...this.props} isOpen={isCreatingTeam}
                    toggle={() => this.setState({ isCreatingTeam: !this.state.isCreatingTeam, isTeamSelectionOpen: true })} fnOk={this.toogleTeamSelection} />}
            </section>
        )
    }
}

export default Seasons;
import React from 'react';
import SeasonMenu from './menu/SeasonMenu';
import Header from './common/headers/Header.Admin2';
import fakeAuth from '../fakeAuth';
import paths from '../ioc/paths';
import Spotlight from './common/modals/Spotlight';


class Season extends React.Component {

  state = {
    menuHidden: localStorage.getItem("MENU.HIDDEN") === 'true' || false
  }

  componentWillMount = () => {
    if (fakeAuth.isAuthenticated()) {
      this.props.login(fakeAuth.getCredentials());
    } else {
      fakeAuth.signout(() => {
        this.props.router.push('/login'); //from.pathname);
      });
    }
  }

  hideMenu = () => {
    const { menuHidden } = this.state;
    this.setState({
      menuHidden: !menuHidden
    }, () => {
      localStorage.setItem("MENU.HIDDEN", this.state.menuHidden);
    });
  }

  toggleSpotlight = () => {
    const { spotlightOn } = this.props;
    this.props.spotlightActions &&
      this.props.spotlightActions[spotlightOn ? 'close' : 'open']();
  }

  render() {

    const { menuHidden } = this.state,
      { params, spotlightOn } = this.props;

    return (

      <section id="season">
        <Header {...this.props} paths={paths} spot={true} />        
        <section id="main-content">
          <SeasonMenu idSeason={params.idSeason} {...this.props} />
          <section id="content-wrapper" 
          style={{ left: menuHidden ? 0 : '12em' }} className={`${menuHidden ? 'hidden' : ''}`}>
            {React.cloneElement(this.props.children, this.props)}
          </section>
        </section>
        <button id='hide-menu-action'
          style={{ width: 13, padding: '1em 0', backgroundColor: 'white', top: '50%', }}
          onClick={this.hideMenu}
          className={`btn btn-link ${menuHidden ? 'hidden' : ''}`}>
          <i className={`fas ${menuHidden ? 'fa-caret-right' : 'fa-caret-left'}`} />
        </button>
        {spotlightOn && <Spotlight isOpen={spotlightOn} toggle={this.toggleSpotlight} {...this.props} />}
      </section>

    );
  }
}

export default Season;

import React from 'react';
import Loader from '../../../common/Loader';
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class Comp extends React.Component {

  state = {
    bootstrapped: false
  }

  // Lifecycle
  componentWillMount() {

    const { params, setTitle } = this.props;
    this.props.seasonActions &&
      this.props.seasonActions.fetchDashboard &&
      this.props.seasonActions.fetchDashboard(params.idSeason);
    this.props.reportsActions && this.props.reportsActions.fetchGames(params.idSeason);

    setTitle && setTitle({
      main: 'Report | Games'
    });
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { setTitle } = nextProps;

      setTitle && setTitle({
        sub: 'Report | Games',
        main: nextProps.season.Name || null
      });
    }

    if (nextProps.report) {
      this.setState({
        bootstrapped: true
      })
    }
  }
  componentWillUnmount() {
    this.props.reportsActions && this.props.reportsActions.clearReport()
  }
  //

  render() {
    const { bootstrapped } = this.state,
      { report, season } = this.props;

    return <section>
      <div className="filters-wrapper d-flex flex-row">
        <div className="ml-auto p-2">
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Games ${season.Name}`}
            sheet="Games" buttonText="Export as XLS" />
        </div>
      </div>
      <div style={{ position: "absolute", top: '3em', bottom: 0, left: 0, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">
        {!bootstrapped && <Loader key="loader" message={`Fetching Data, please wait...`} />}
        {bootstrapped && (
          <section className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', margin: 'auto' }}>
            <table id="table-to-xls" className="table table-sm font-10">
              <thead>
                <tr>
                  <th>Game Id</th>
                  <th>Division</th>
                  <th>Location</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Flight</th>
                  <th>(H) Id Team Div.</th>
                  <th>Home Team</th>
                  <th>Away Team</th>
                  <th>(A) Id Team Div.</th>
                  <th>Home Score</th>
                  <th>Away Score</th>
                  <th>Playoff</th>
                  <th>Season</th>
                </tr>
              </thead>
              <tbody>
                {report && report.map((record, i) => (
                  <tr key={i}>
                    <td>
                      {record.IdGame}
                    </td>
                    <td>
                      {record.DivisionName}
                    </td>
                    <td>
                      {record.LocationName}
                    </td>
                    <td>
                      {/* record.DateTimeStart_Date_Format */}
                      {moment(record.DateTimeStart).utc().format('MM/DD/YYYY')}
                    </td>
                    <td>
                      {`${moment(record.DateTimeStart).utc().format('HH:mmA')} ${record.TimeZone_Abbreviation}`}
                    </td>
                    <td>
                      {record.DivisionFlight}
                    </td>
                    <td>
                      {record.IdTeamDivisionHome}
                    </td>
                    <td>
                      {record.TeamHome}
                    </td>
                    <td>
                      {record.TeamAway}
                    </td>
                    <td>
                      {record.IdTeamDivisionAway}
                    </td>
                    <td>
                      {record.TeamHome_Score}
                    </td>
                    <td>
                      {record.TeamAway_Score}
                    </td>
                    <td>
                      {record.IsPlayOff ? 'Playoff' : 'Poolplay'}
                    </td>
                    <td>
                      {record.SeasonName}
                    </td>
                  </tr>)
                )}
              </tbody>
            </table>
          </section>)}
      </div>
    </section>
  }
}

export default Comp;
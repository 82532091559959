import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash';

class ShareMatch extends React.Component {
    state = { data: null }

    componentWillReceiveProps = nextProps => {
        const { share_match } = nextProps;
        if (share_match) {
            let image = '', clip = '';
            switch (share_match.ReportType) {
                case 1:
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/profile/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/profile/${share_match.IdMatch}`;
                    break;
                case 2:
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/match/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/match/${share_match.IdMatch}`;
                    break;
                case 3:
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/team/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/team/${share_match.IdMatch}`;
                    break;
                case 10: // Notes (GOOD)
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/note/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/note/${share_match.IdMatch}`;
                    break;
                case 11: // Attributes (GOOD)
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/attributes/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/attributes/${share_match.IdMatch}`;
                    break;
                case 12: // Player Review
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/pr/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/pr/${share_match.IdMatch}`;
                    break;
                case 80: // Custom TEam                    
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/personal_team/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/personal_team/${share_match.IdMatch}`;
                    break;
                case 86: // Custom Attributes Template
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/attributes_template/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/attributes_template/${share_match.IdMatch}`;                    
                    break;
                case 90: // Custom Player                    
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/personal_player/${share_match.IdMatch}`;
                    clip = `https://matchreports.thesqwad.com/scout/personal_player/${share_match.IdMatch}`;
                    break;
                default:
                    clip = `https://matchreports.thesqwad.com/scout/match/${share_match.IdMatch}`;
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/match/${share_match.IdMatch}`;
                    break;
            }
            this.setState({ share_match, image, clip });
        }
    }

    render() {
        const { share_match, image, clip } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.share_match ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, width: 400, right: 0, paddingTop: 70, zIndex: 200, borderLeft: '1px solid black' }}>

                <section className="bg-white w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {share_match && <section className="w3-container d-flex flex-column">

                        <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25 }} className="bg-gray d-flex flex-column justify-content-center align-self-start">
                            <i className="fas fa-times black font-30 align-self-center" />
                        </div>
                        <img src={image} className="w-100 mt-3" />

                        <span className="mt-2 text-center">Scan this code with</span>
                        <img src="/images/logos/rc.png" className="m-auto" style={{ height: 50, width: 50 }} />
                        <div style={{ height: 30 }} />
                        <span className="mt-2 text-center">- OR -</span>
                        <CopyToClipboard text={clip}
                            onCopy={() => this.setState({ copySuccess: true })}>
                            <button className={`btn btn-${this.state.copySuccess ? 'success' : 'link bordered'} m-auto`}>
                                <i className="fas fa-clipboard blue" /> Copy link to your clipboard</button>
                        </CopyToClipboard>
                        <div style={{ height: 30 }} />
                    </section>}
                </section>

            </Animated>
        </section>
    }
}

export default ShareMatch;
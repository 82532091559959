import React from 'react';
import { Link } from 'react-router';
import RevenueChart from './revenue_chart';
import TeamsChart from './pie_chart';
import PaymentTypeChart from './payment_types';
import Divisions from './divisions/index';
import Players from '../../common/modals/Players';
import Details from '../../common/modals/Details';
import Teams from '../../common/modals/Teams';
import { Animated } from 'react-animated-css';

class Dashboard extends React.Component {

  state = {
    bootstrapped: false,
    isPlayersOpen: false,
    isTeamsOpne: false,
    selectedDivision: null,
    selectedDetails: null,
    mode: parseInt(localStorage.getItem('app:divisions:mode') || 1, 10)
  }

  // Lifecycle
  componentWillMount() {
    const { params, setTitle, } = this.props;
    this.props.seasonActions && this.props.seasonActions.fetchDashboard(params.idSeason);
    this.props.seasonActions && this.props.seasonActions.fetchScoutRegistrations(params.idSeason);
    setTitle && setTitle({ main: 'Dashboard' });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.season !== this.props.season) {
      const { mode } = this.state, { season } = nextProps;     

      const { setTitle } = nextProps;

      setTitle && setTitle({
        sub: 'Dashboard',
        main: nextProps.season.Name || null
      });

      this.setState({ bootstrapped: true, mode: season.AllowsScoutRegistration ? mode : (season.IdSeasonType === 3 ? 2 : 1) });
    }
  }
  componentWillUnmount = () => {
    this.props.clearGenders && this.props.clearGenders();
  }
  //

  togglePopup = () => {
    this.setState((state) => {
      return { isPlayersOpen: false, isTeamsOpen: false }
    });
  }

  fnSelectDivision = (division, mode) => {
    this.setState((state) => {
      return {
        isPlayersOpen: mode === 1,
        isTeamsOpen: mode === 2,
        selectedDivision: division
      }
    });
  }

  fnSelectDetails = selectedDetails => {
    this.setState({ selectedDetails, isDetailsOpen: true });
  }
  toggleDetails = () => this.setState({ isDetailsOpen: !this.state.isDetailsOpen });

  onMode = mode => {
    //if (mode !== 3)
    localStorage.setItem('app:divisions:mode', mode);
    this.setState({ mode });
  }

  render() {

    const { isPlayersOpen, isTeamsOpen, selectedDivision, isDetailsOpen, selectedDetails, mode, bootstrapped } = this.state,
      { season, divisions, league, } = this.props;

    return (
      <section>
        {bootstrapped && <Animated animationIn='slideInRight' animationInDuration={200} className="w3-container mt-4">

          <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>
            <div className="w-100 black">
              <h3 className="sqwad-font toUpper line1">{season.Name || season.SeasonName}</h3>
              <Link to="/leagues"><h6 className="block">{league ? `${(league.Name || league.LeagueName)} Leagues` : ''}</h6></Link>
            </div>
          </div>

          <hr />

          {/* TABS */}
          <div className="d-flex flex-row w-100 justify-content-center">
            <button onClick={() => this.onMode(1)} className={`font-16 line1 sqwad-font toUpper align-self-center btn btn-link ${mode === 1 ? 'blue' : 'black'}`}>Players</button>
            <span className="align-self-center ">|</span>
            <button onClick={() => this.onMode(2)} className={`font-16 line1 sqwad-font toUpper align-self-center btn btn-link ${mode === 2 ? 'blue' : 'black'}`}>Teams</button>
            {season && season.AllowsScoutRegistration === 1 && <span className="align-self-center ">|</span>}
            {season && season.AllowsScoutRegistration === 1 && <button onClick={() => this.onMode(3)} className={`font-16 line1 sqwad-font toUpper align-self-center btn btn-link ${mode === 3 ? 'blue' : 'black'}`}>Scouts</button>}
          </div>

          {/* CHARTS */}
          {[1, 2].indexOf(mode) !== -1 && <div className="w3-content mt-2" style={{ maxWidth: 1285 }}>
            <div className="d-flex flex-row mt-3">
              <div style={{ height: 350, minWidth: 370 }} className={`card w-${season.DontShowAccounting ? '100' : '50'} shadow`}>
                {season && season.IdSeason && <TeamsChart season={season} divisions={divisions} />}
              </div>
              {season && !season.DontShowAccounting && <div style={{ width: 30 }} className="" />}
              {season && !season.DontShowAccounting && <div className="w-100 d-flex flex-column ">
                <div className="h-100 card w-100 shadow">
                  {season && season.IdSeason && <RevenueChart details={this.props.details} fnSelectDetails={this.fnSelectDetails} season={this.props.season} total={this.props.season.Charges} received={this.props.season.Received} />}
                </div>
                <div style={{ height: 30 }} />
                <div className="h-100 card w-100 shadow">
                  {season && season.IdSeason && <PaymentTypeChart season={season} />}
                </div>
              </div>}
            </div>
          </div>}

          {/* DIVISIONS */}
          <Divisions {...this.props} mode={mode} fnSelectDivision={this.fnSelectDivision} />

        </Animated>}

        {isPlayersOpen && <Players {...this.props} isOpen={isPlayersOpen} toggle={this.togglePopup} division={selectedDivision} />}
        {isTeamsOpen && <Teams {...this.props} isOpen={isTeamsOpen} toggle={this.togglePopup} division={selectedDivision} />}
        {isDetailsOpen && <Details details={selectedDetails} toggle={this.toggleDetails} isOpen={isDetailsOpen} />}

      </section>
    )
  }
}

export default Dashboard;

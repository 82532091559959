import React from 'react';
import { Animated } from 'react-animated-css';
import PlayerSelection from './PlayerSelection';
import CoachSelection from './CoachSelection';

class PersonSelection extends React.Component {
    state = { bootstrapped: false }

    componentWillMount() {        
        this.setState({ bootstrapped: true, stage: 1 });
    }    

    onClose = () => {
        this.setState({ bootstrapped: false }, () => {
            const { close } = this.props;
            setTimeout(() => { close && close() }, 500);
        });
    }

    onBack = () => {
        switch (this.state.stage) {
            case 1: this.onClose(); break;
            case 2: this.setState({ stage: 1 }); break;
            case 3: this.setState({ stage: 1 }); break;
            default: break;
        }
    }

    onOk = ({ selectedUser, selectedRole, mode }) => {
        if (selectedUser) {
            this.props.fnOk && this.props.fnOk({
                selectedUser,
                selectedRole,
                selectedSeason: this.props.season,
                selectedLeague: this.props.league,
                mode,
                familyRegistration: this.props.familyRegistration
            });
        }
    }

    stage2 = () => this.setState({ stage: 2 });

    stage3 = () => this.setState({ stage: 3 });

    render() {

        const { bootstrapped, stage } = this.state;

        return <section style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>
            <div className="dark-overlay" onClick={this.onClose} />
            <Animated style={{ width: 300, position: 'absolute', top: 0, right: 0, bottom: 0, overflowY: 'auto' }} className="bg-gray d-flex flex-column montserrat"
                isVisible={bootstrapped} animationOut="slideOutRight" animationIn='slideInRight' animationInDelay={200} animationInDuration={500} animationOutDuration={500}>
                <div style={{ height: 50 }} className="w-100 p-2 d-flex flex-row">
                    <i className="icon-arrow-left jeff_blue font-20 align-self-center" onClick={this.onBack} />
                    <span className="jeff_blue ml-auto font-12 montserrat toUpper align-self-center">Who's Registering</span>
                    <i className="shopping_cart white-ish font-24 ml-auto align-self-center" />
                </div>
                {stage === 1 && <div className="d-flex flex-column justify-content-center p-4 h-100 ">

                    <div onClick={this.stage2} className="pointer w-100 tile card shadow-box p-4 d-flex flex-column justify-content-center align-self-center" style={{ height: 160 }}>
                        <i className="icon-family_registration black font-40 align-self-center" />
                        <span className="black font-20 toBold align-self-center">PLAYER</span>
                        <span className="font-10 gray align-self-center">Registration</span>
                    </div>

                    <div onClick={this.stage3} className="pointer w-100 tile card shadow-box p-4 d-flex flex-column justify-content-center align-self-center mt-4" style={{ height: 160 }}>
                        <i className="icon-coach_registration black font-40 align-self-center" />
                        <span className="black font-20 toBold align-self-center">COACH</span>
                        <span className="font-10 gray align-self-center">Registration</span>
                    </div>

                </div>}
                {stage === 2 && <PlayerSelection {...this.props} onOk={this.onOk} />}
                {stage === 3 && <CoachSelection {...this.props} onOk={this.onOk} />}
            </Animated>

        </section>
    }
}

export default PersonSelection;

import React from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import moment from 'moment';
import { formatPrice, detectCreditCardType } from '../../../helpers';
import BlueishHeader from '../../common/things/BlueishHeader';
import request from 'superagent';
import config from '../../../config';
import '../../externals/signature-pad.css';
import _ from 'lodash';
var SignaturePad = require('react-signature-pad');

class TeamPremiumModal extends React.Component {

    state = {
        target: null,
        mode: 1,
        signed: false,
        invalidCC: false,
        paying: false,
        paid: false,
        error: false,
        discounts: [],
        the_discount: 0
    }

    // Lifecycle
    componentWillMount() {
        const { target, selectedTeam } = this.props;
        this.setState({ target, team: selectedTeam });
    }
    //   

    validateNewCard = () => {
        var valid = true;
        if (!this.txtCardNumber) return true; // This is when the coupon code is 100%
        if (!this.txtCardNumber.value ||
            !this.txtNameOnCard.value ||
            !this.txtExpDate.value ||
            !this.txtCVV.value ||
            !this.txtZipCode.value) {
            valid = false;
        } else if (!detectCreditCardType(this.txtCardNumber.value).Name) {
            valid = false;
        }
        return valid;
    }

    onMode = mode => this.setState({ mode });

    onPay = () => {
        const { target, discounts } = this.state;

        if (this.validateNewCard()) {
            
            let selectedCard = {
                number: (this.txtCardNumber || {}).value,
                name: (this.txtNameOnCard || {}).value,
                cvv: (this.txtCVV || {}).value,
                expdate: (this.txtExpDate || {}).value,
                zip: (this.txtZipCode || {}).value,
            }
            this.setState({ invalidCC: false, mode: 4, paying: true, paid: false, error: false }, () => {

                request.post(`${config.apiEndpoint}/api/v6/subscriptions/${target.PlanType === 1 ? 'subscribe_team' : 'subscribe_users'}`)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .send({ IdTeam: this.state.team.IdTeam, IdPlan: target.IdPlan, card: selectedCard, discounts })
                    .then((data) => {
                        if (data.body.success) {
                            this.setState({
                                step: 4,
                                message: data.body.transactionId,
                                paying: false,
                                paid: true
                            }, () => {
                                this.props.success && this.props.success(target);
                            });

                            // Remove that cart
                        } else {
                            this.setState({
                                step: 4,
                                message: data.body.message,
                                paying: false, error: true
                            })
                        }
                    }, ({ response: { body } }) => {
                        this.setState({
                            step: 4,
                            message: body.error.message,
                            paying: false,
                            error: true
                        });
                    });
            });

        } else {
            this.setState({ invalidCC: true })
        }
    }

    continue = () => {
        var signature = this.refs.mySignature;
        if (signature && signature.isEmpty()) {
            this.setState({
                signatureError: true
            });
            document.getElementById('terms-and-conditions').scrollIntoView();

        } else {
            this.setState({
                signatureDataURL: signature.toDataURL()
            }, function () {
                document.getElementById('btnPay').scrollIntoView();
            });
        }
    }

    clearSignature = () => {
        var signature = this.refs.mySignature;
        signature.clear();
        this.setState({
            signatureDataURL: null,
            signed: false
        })
    }

    signatureEnd = () => {
        this.setState({
            signed: true
        });
    }

    onCoupon = () => {
        const { discounts, target } = this.state;
        let { the_discount } = this.state;
        request.post(`${config.apiEndpoint}/api/v4/coupons/apply_membership`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({ CouponName: this.txtCoupon.value })
            .then(({ body }) => {
                // Only add it as an adjustment if the fee is not bundled                    
                const { coupon } = body;

                if (_.find(discounts, c => c.coupon.IdCoupon === coupon.IdCoupon)) {
                    // COUPON ALREADY APPLIED
                    return;
                }

                discounts.push(_.pick(coupon, 'IdCoupon', 'AmountPerCoupon', 'PercentPerCoupon', 'PromoDays', 'CouponName'));

                /*discounts.push({
                    coupon,
                    Code: this.txtCoupon.value,
                    Value: coupon.AmountPerCoupon || (target.Amount * coupon.PercentPerCoupon / 100)
                });*/
                the_discount += (coupon.AmountPerCoupon || (target.Amount * coupon.PercentPerCoupon / 100));
                this.setState({ discounts, the_discount, coupon_error: false });
            }, (error) => {
                this.setState({ coupon_error: true })
            });
    }

    render() {
        const { isOpen, toggle } = this.props,
            { target, mode, signatureError, signed, invalidCC, paying, paid, error, the_discount } = this.state;

        return (
            <Modal isOpen={isOpen} style={{ width: 500 }} toggle={toggle}>
                <ModalBody className="container-fluid no-margin no-padding" style={{ background: `url(/images/backgrounds/grass.png) no-repeat center center`, backgroundSize: 'cover', border: '1px solid white', borderRadius: 5, position: 'relative' }}>
                    {mode !== 4 && <div style={{ position: 'absolute', backgroundColor: 'black', opacity: '0.5' }} className="h-100 w-100" />}
                    {/* Info */}
                    {mode === 1 && (
                        <div className="d-flex flex-row justify-content-center" style={{ position: 'relative', height: 750 }}>
                            <div className="h-auto w-100 align-self-center">
                                <span className="bigest-font white block text-center sqwad-font">SQWAD</span>
                                <div style={{ height: 150, position: 'relative' }} className="w-100">
                                    <div style={{ backgroundColor: '#FFFFFF', opacity: 0.2, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />
                                    <div className="h-100 w-100 d-flex flex-column justify-content-center">
                                        <span className="white toUpper font-16 align-self-center sqwad-font">{target.Name}</span>
                                        <span className="white font-16 align-self-center montserrat">{formatPrice(target.Amount * 100)} per month</span>
                                        <span className="white font-10 align-self-center montserrat">Auto-renewed every {target.Duration} days</span>
                                    </div>
                                </div>

                                <ul className="white text-left mt-4 montserrat mx-auto" style={{ width: 300 }}>
                                    <li className="text-center"><span className="font-24 white toBold line1">Digital Team Brochure</span></li>
                                    <li className="text-center mb-3"><span className="font-10 white line1">Get your athletes recruited!</span></li>
                                    <li className="d-flex flex-row line1 mb-1"><i className="icon-bar-chart mr-2 font-20 align-self-center" /><span className="ml-1 align-self-center">Player Evaluations</span></li>
                                    <li className="d-flex flex-row line1 mb-1"><i className="icon-recreational mr-2 font-20" />Connect your Athletes to Coaches</li>
                                    <li className="d-flex flex-row line1 mb-1"><i className="icon-upload mr-2 font-20" />Upload and store Game Film</li>
                                    <li className="d-flex flex-row line1 mb-1"><i className="icon-database mr-2 font-20" />{target.StorageLabel} Storage space</li>
                                    <li className="d-flex flex-row line1 mb-1"><i className="icon-video-player-1 mr-2 font-20" />Watch and Review</li>
                                    <li className="d-flex flex-row line1"><i className="icon-eye mr-2 font-20" />Perfect for Instructional Video Review</li>
                                </ul>
                                <div style={{ height: 40 }} />
                                <div onClick={() => this.onMode(2)} style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 20, width: 150 }} className="m-auto p-2 text-center pointer" ><span className="white">PURCHASE</span></div>
                                {target.Canceled && <div>
                                    <span className="block font-12 mt-2 text-center white">Your current Subscription is cancelled.<br />It will be active until {moment(target.StartDate.split('T')[0], 'YYYY-MM-DD').add(target.Duration, 'days').format('MMM Do YYYY')}</span>
                                </div>}
                                <span className="mt-4 block font-10 text-center white">Terms of Service and Privacy Policy</span>
                            </div>
                            <i className="fa fa-times font-20 white" style={{ position: 'absolute', top: 10, right: 10 }} onClick={toggle} />
                        </div>
                    )}

                    {/* Disclaimer */}
                    {mode === 2 && (
                        <div className="h-100" style={{ position: 'relative' }}>
                            <span className="bg-info p-2 text-center w-100 block white">Terms and Payment</span>
                            <div id="terms-and-conditions" style={{ height: 359, overflowY: 'scroll' }} className="white m-auto font-8">
                                {this.props.disclaimer.sportslogic && (
                                    <section className="bg-white black">
                                        <div className="p-2" dangerouslySetInnerHTML={{ __html: this.props.disclaimer.sportslogic }} />
                                    </section>
                                )}
                            </div>
                            { /* Signature */}
                            {!signatureError && <BlueishHeader message="Please Sign Here" />}
                            {signatureError && <div className="bg-warning p-2 big-font text-center">Please Sign!</div>}
                            <div className="m-auto w-100" style={{ position: 'relative' }}>
                                <SignaturePad onEnd={this.signatureEnd} ref="mySignature" className='w-100' />
                                <button className="btn btn-link float-right" onClick={this.clearSignature} style={{ position: 'absolute', top: 0, right: 0 }}>Clear</button>
                            </div>
                            <button id="" className={`btn btn-lg btn-block btn-${signed ? 'success' : 'info'} bigest-font`} onClick={() => this.onMode(3)}>Continue</button>

                            <i className="fa fa-arrow-left font-18 white" style={{ position: 'absolute', top: 6, left: 8 }} onClick={() => this.onMode(1)} />

                        </div>
                    )}

                    {/* Payment */}
                    {mode === 3 && <div className="h-100" style={{ position: 'relative' }}>
                        <i className="fa icon-logo-header bigest-font white block text-center"></i>
                        <div style={{ height: 150, position: 'relative' }} className="w-100">
                            <div style={{ backgroundColor: '#FFFFFF', opacity: 0.2, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />
                            <div className="h-100 w-100 d-flex flex-column justify-content-center">
                                <span className="white toUpper font-16 align-self-center">{target.Name}</span>
                                <span className="white font-16 align-self-center">{formatPrice(target.Amount * 100)} per month</span>
                                <span className="white font-10 align-self-center">Auto-renewed every {target.Duration} days</span>
                                {the_discount > 0 && <span className="black font-10">Discount: -{formatPrice(the_discount * 100)}</span>}
                            </div>
                        </div>
                        <BlueishHeader message="Coupon Code" />
                        <section className={`m-auto font-12 align-self-center bg-white p-2`} style={{ position: 'relative' }}>
                            <div className="d-flex flex-row">
                                <input className="form-control align-self-center p-4 w-100" maxLength="16" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtCoupon = i} />
                                <button onClick={this.onCoupon} className="btn btn-default ml-2" style={{ backgroundColor: 'gray', color: 'white' }}>Apply</button>
                                {the_discount > 0 && <span className='green font-12 ml-2 align-self-center'>Discount applied!</span>}
                                {this.state.coupon_error && <span className='red font-12 ml-2 align-self-center'>Invalid Coupon Code</span>}
                            </div>
                        </section>
                        {(target.Amount - the_discount) > 0 && !invalidCC && <BlueishHeader message="Credit Card Information" />}
                        {(target.Amount - the_discount) > 0 && invalidCC && <div className="bg-warning p-2 big-font text-center">Invalid Credit Card!</div>}
                        {(target.Amount - the_discount) > 0 && <section className={`mx-auto font-12 align-self-center bg-white`} style={{ position: 'relative' }}>
                            <div className="d-flex flex-row pt-2 pl-2 pr-2">
                                <input className="form-control align-self-center p-4 w-100" placeholder="Card Number" maxLength="16" type="text" ref={(i) => this.txtCardNumber = i} />
                            </div>
                            <div className="d-flex flex-row pt-2 pl-2 pr-2">
                                <input className="form-control align-self-center p-4 w-100" placeholder="Name on Card" type="text" ref={(i) => this.txtNameOnCard = i} />
                            </div>
                            <div className="d-flex flex-row pt-2 pl-2 pr-2">
                                <input className="form-control align-self-center p-4 w-100" placeholder="Exp. Date (MMYY)" maxLength="4" type="text" ref={(i) => this.txtExpDate = i} />
                                <div style={{ width: 20 }} />
                                <input className="form-control align-self-center p-4 w-100" placeholder="CVV" type="text" maxLength="4" ref={(i) => this.txtCVV = i} />
                            </div>
                            <div className="d-flex flex-row py-2 px-2">
                                <input className="form-control align-self-center p-4 w-50" placeholder="Zip | Postal" type="text" ref={(i) => this.txtZipCode = i} />
                            </div>
                        </section>}
                        <button id="btnPay" className="btn btn-lg btn-block btn-success bigest-font align-self-end" onClick={this.onPay}>{`Pay ${formatPrice((target.Amount - the_discount) * 100)}`}</button>


                        <i className="fa fa-arrow-left font-20 white" style={{ position: 'absolute', top: 10, left: 10 }} onClick={() => this.onMode(2)} />
                    </div>}
                    {mode === 4 && <div className="bg-dark-blue d-flex flex-row justify-content-center" style={{ height: 400 }}>
                        {paying && <div className="h-auto d-flex flex-column justify-content-center no-margin text-center" key="step1">
                            <i className="fa fa-credit-card bigest-font heart align-self-center white" />
                            <h1 className="margin-top-half align-self-center white">Processing, please wait...</h1>
                        </div>}
                        {paid && <div className="h-auto w-100 d-flex flex-column justify-content-center no-margin text-center bg-success" key="step2" onClick={toggle}>
                            <i className="fa fa-check white bigest-font" />
                            <h1 className="white">Registration successful</h1>
                            {/*<h3 className="white">{formatPrice(target.Amount * 100)}</h3>*/}
                            <h3 className="white font-8">{this.state.message}</h3>
                        </div>}
                        {error && <div className="h-auto w-100 d-flex flex-column justify-content-center no-margin text-center bg-danger"
                            onClick={() => this.onMode(3)} key="step3" style={{ position: 'relative' }}>
                            <i className="fa fa-times white bigest-font" />
                            <h1 className="white">Registration failed</h1>
                            <h3 className="white pl-2 pr-2">{this.state.message}</h3>
                            <i className="fa fa-arrow-left font-20 white" style={{ position: 'absolute', top: 10, left: 10 }} />
                        </div>}
                    </div>}
                </ModalBody>
            </Modal>
        )
    }
}

export default TeamPremiumModal;

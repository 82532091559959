import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import DivisionsTable from './table';
import request from 'superagent';
import config from '../../../../../../config'

class Wizzard extends React.Component {

    state = {
        lock: false,
        selectable: false,
        creating: false,
        activeTab: 1,
        required: false,
        newTemplate: {
            Name: '',
            Divisions: []
        },
        signal: 1
    }

    componentWillMount() {
        this.props.leagueActions && this.props.leagueActions.fetchDivisionTemplates(this.props.season.IdLeague);
        this.setState({ bootstrapped: true, season: this.props.season })
    }

    componentWillReceiveProps = nextProps => {
        const { templates } = nextProps;
        this.setState({ templates })
    }

    onSelect = index => {
        const { templates } = this.state;
        templates.forEach((t, i) => {
            t.selected = i === index ? true : false;
        });
        this.setState({ templates });
    }

    onAddDivision = () => {
        const { newTemplate } = this.state;
        newTemplate.Divisions.push({
            DivisionStatus: 'Open', IdDivisionStatus: 1,
            IdGender: 1, Gender: 'Male'
        });
        this.setState({ newTemplate });
    }

    onSave = (divisions) => {

        const { templates } = this.state;       

        templates.push({ Divisions: divisions, Name: this.txtName.value });
        this.setState({ saving: true, templates, }, () => {
            // CALL API
            request.post(`${config.apiEndpoint}/api/v4/leagues/division_template/${this.props.season.IdLeague}`)
                .send({ Divisions: divisions, Name: this.txtName.value })
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .then(({ body }) => {
                    this.props.leagueActions && this.props.leagueActions.fetchDivisionTemplates(this.props.season.IdLeague);
                    this.setState({ saving: false, creating: false })
                });
        });
    }

    onApply = () => {
        const { templates } = this.state;
        let template = _.find(templates, t => t.selected);
        if (template) { this.props.fnApply && this.props.fnApply(template) }
        this.props.toggle && this.props.toggle();
    }

    render() {

        const { isOpen, toggle } = this.props,
            { error, creating, templates = [], bootstrapped, saving } = this.state;

        return (
            <Modal id="questions-wizzard" isOpen={isOpen} style={{ width: 1000 }}>
                <ModalHeader className="d-flex flex-row p-3 bg-blue-dark white" style={{ width: 1000 }} toggle={toggle}>
                    Apply Template
                </ModalHeader>
                {!creating && <ModalBody className="p-3">
                    <div className="d-flex flex-row mb-2 mx-2 mt-2">
                        <h4>Templates</h4>
                        {!creating && <button onClick={() => this.setState({ creating: true })} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 100 }} className="green ml-auto p-2 align-self-center block">
                            <i className="fas fa-plus mr-2" />Create</button>}
                    </div>

                    {templates.map((t, index) => <div onClick={() => this.onSelect(index)} className={`d-flex flex-row pointer p-3`} key={index} style={{ borderBottom: `1px solid gray` }}>
                        <span className="align-self-center w-100">{t.Name || t.DivisionTemplateHeader}</span>
                        <span className="align-self-center w-100 ml-auto">{t.Divisions.length} Division(s)</span>
                        <span className={`ml-auto text-right ${t.selected ? 'green' : 'blue underlined'}`} style={{ width: 210 }}>{t.selected ? <i className="fas fa-check ml-auto" /> : 'Use Template'}</span>
                    </div>)}

                </ModalBody>}

                {!creating && <ModalBody className="d-flex flex-column" style={{ width: 1000, minHeight: 400 }}>
                    {bootstrapped && !templates.length && <div className="d-flex justify-content-center centered-message" key="noDivisionSelected">
                        <h1 className="black align-self-center block text-center">This league has<br />no templates created</h1>
                    </div>}
                    {false && templates.map((t, index) => <button onClick={() => this.onSelect(index)} className={`d-flex flex-row btn btn-${t.selected ? 'info' : 'link'}`} key={index}>
                        <span className="align-self-center">{t.Name || t.DivisionTemplateHeader}</span>
                        <span className="align-self-center ml-auto">({t.Divisions.length} Divisions)</span>
                        <i className="fa fa-check white ml-2 align-self-center" />
                    </button>)}
                </ModalBody>}

                {creating && <ModalBody style={{ width: 1000, minHeight: 400, overflowY: 'auto' }}>
                    <div className="m-3">
                        <h3>Template's Name</h3>
                        <div className="input-group w-100 align-self-center">
                            <input type="text" className="form-control" ref={(i) => this.txtName = i} />
                        </div>
                        <div className="mt-2">
                            <h3>Divisions</h3>
                            <DivisionsTable fnSave={this.onSave} signal={this.state.signal} key="table" divisions={this.state.newTemplate.Divisions} />
                        </div>
                        <button style={{ border: '1px solid #2cad3c', borderRadius: 20,  }} className="green ml-auto py-2 px-4 align-self-center block" onClick={this.onAddDivision}><i className="fa fa-plus mr-2" />Add Division</button>
                    </div>
                    <div style={{ width: 20 }} />
                </ModalBody>}

                {error && <ModalBody className="bg-danger white p-2 font-10" style={{ width: 1000 }}>{error.message || 'An error has occurred'}</ModalBody>}
                {!creating && <ModalFooter style={{ width: 1000 }}>
                    {_.find(templates, t => t.selected) && <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 150 }} className="green ml-auto p-2 align-self-center block" onClick={this.onApply}>Apply Template</button>}
                    <button className="btn btn-link btn-sm" onClick={toggle}>Close</button>
                </ModalFooter>}

                {creating && <ModalFooter style={{ width: 1000 }}>
                    {!saving && <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 150 }} className="green ml-auto p-2 align-self-center block" onClick={() => this.setState({ signal: this.state.signal + 1 })}>Save Template</button>}
                    {!saving && <button className="btn btn-link btn-sm" onClick={() => this.setState({ creating: false })}>Cancel</button>}
                    {saving && <div className="bg-warning p-2 block w-100 d-flex flex-row justify-content-center">
                        <span className="align-self-center">Saving Template, please wait</span>
                    </div>}
                </ModalFooter>}

            </Modal>
        )
    }
}

export default Wizzard;

import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody } from 'reactstrap';
import request from 'superagent';
import config from '../../../../config';
import _ from 'lodash';

class LeagueAdministrators extends React.Component {
    state = {};
    componentWillMount() {
        const { league } = this.props;
        this.setState({ league });
        request.get(`${config.apiEndpoint}/api/v4/leagues/administrators/${league.IdLeague || league.Id}`)
            .then(({ body }) => {
                this.setState({ administrators: body.administrators });
            });
    }

    search_email = (e) => {
        e && e.preventDefault && e.preventDefault();
        if (this.state.fetching_user) return;
        const email = this.txtSearchEmail.value.trim();
        if (!email) return;
        // TODO: validate this email    
        this.setState({ fetching_user: true, no_users_found: false, invalid_sqwad_owner: false, error: null }, () => {
            // Call API
            request.post(`${config.apiEndpoint}/api/v4/family/members/by_email`)
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .send({ email })
                .then((data) => {
                    if (data.body.success) {

                        // Decrypt User Info
                        //data.body.users.forEach(humanize_user);                        

                        this.setState({
                            fetching_user: false,
                            users: data.body.users,
                            no_users_found: data.body.users.length ? false : true,
                            user_index: 0
                        });
                    } else
                        this.setState({ fetching_user: false, no_users_found: true });

                });
        });
    }

    onAdd = () => {
        const { administrators, users, user_index, league } = this.state;
        if (_.find(administrators, a => a.IdUser === users[user_index].IdUser)) return;
        administrators.push(users[user_index]);
        this.setState({ administrators });
        request.post(`${config.apiEndpoint}/api/v4/leagues/administrators/${league.IdLeague || league.Id}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({ IdUser: users[user_index].IdUser })
            .then(() => { });
        // CALL API
    }
    render() {
        const { league, administrators } = this.state;
        return <ModalBody>
            <hr className="w-100" />
            {administrators && <div className="p-4">
                <h4>League Administrators</h4>
                <div className="d-flex" style={{ minHeight: 300 }}>
                    <div className="w-100">
                        <section className="pl-3">

                            <form className="w-100 mt-3" key="search-form">
                                <div className={`black-input ${false ? 'error-input' : ''} d-flex mt-2`} id="txt_search">
                                    <input className="w-100 p-3 " type="text" name="search_email" placeholder="Search by Email" ref={(input) => this.txtSearchEmail = input} />
                                    <button onClick={this.search_email} className="btn btn-link" type="submit"><i className="fas fa-search font-16 align-self-center black" /></button>
                                </div>
                                <Popover placement="bottom" isOpen={this.state.no_users_found} target={`txt_search`} toggle={() => this.setState({ no_users_found: !this.state.no_users_found })} className="bg-gray-2">
                                    <PopoverBody className="d-flex flex-row justify-content-center">
                                        <i className="fas fa-times red font-8 align-self-center" />
                                        <span className="black font-8 poppins align-self-center ml-3" style={{ maxWidth: 100 }}>Invalid email</span>
                                    </PopoverBody>
                                </Popover>
                            </form>
                        </section>
                        {this.state.users && this.state.users.length > 0 && <section>
                            <section className="pl-3 d-flex flex-row mt-4">
                                <div className="p-2 align-self-center w-50 d-flex justify-content-center" onClick={() => {
                                    const { user_index, users } = this.state;
                                    if (user_index - 1 < 0) this.setState({ user_index: users.length - 1 });
                                    else this.setState({ user_index: user_index - 1 });
                                }}>
                                    <i className="fas fa-chevron-left black font-30" />
                                </div>
                                <div className="w-100 align-self-center justify-content-center d-flex">
                                    <div className="d-flex justify-content-center cover bordered" style={{ width: 100, height: 100, borderRadius: '50%', background: `url(${this.state.users[this.state.user_index].UserImageUrl}?full=80) no-repeat center center #CCCCCC` }} />
                                </div>
                                <div className="p-2 align-self-center w-50 d-flex justify-content-center" onClick={() => {
                                    const { user_index, users } = this.state;
                                    if (user_index + 1 < users.length) this.setState({ user_index: user_index + 1 });
                                    else this.setState({ user_index: 0 });
                                }}>
                                    <i className="fas fa-chevron-right black font-30" />
                                </div>
                            </section>
                            <div className="pl-3 d-flex justify-content-center">
                                <span>{`${this.state.users[this.state.user_index].UserName}`}</span>
                            </div>
                            <div className="pl-3 d-flex justify-content-center mt-4">
                                <button onClick={this.onAdd} className="btn btn-success" style={{ borderRadius: '50%' }}><i className="fas fa-arrow-right" /></button>
                            </div>
                        </section>}
                    </div>
                    <i className="fas fa-arrow-right font-20 black mt-4 p-2" />
                    <div className="w-100">
                        {administrators.map((a, i) => <div key={i} className={`black-input d-flex mt-2 p-3`} id="txt_search">
                            <div className="circular cover align-self-center" style={{ width: 30, height: 30, background: `url(${a.UserImage || a.UserImageUrl}) no-repeat center center black` }} ></div>
                            <div className="d-flex flex-column mx-3 align-self-center">
                                <span className="line1">{`${a.NameFirst} ${a.NameLast}`}</span>
                                <span className="blue font-8 line1">{`${a.EmailAddress}`}</span>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>}
        </ModalBody>
    }
}

export default LeagueAdministrators;

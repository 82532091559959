import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';
import { humanize_user } from '../../helpers';
import _ from 'lodash';

export function* getLeaguesSaga() {
  yield* takeEvery("LEAGUES/FETCH", function* (action) {

    try {
      const result = yield call(function () {
        const url = [config.apiEndpoint, 'api/v4/leagues'].join('/');

        return request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      });

      if (result && result.success) {
        yield put({
          type: 'LEAGUES/SET',
          leagues: result.leagues
        });
        yield put({
          type: 'ADMINISTRATORS/SET',
          admins: result.admins
        });
      } else {
        yield put({
          type: 'LEAGUES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LEAGUES/FETCH_FAILED',
        e
      });
    }
  });
}

///LEAGUE/FEES/FETCH
export function* getLeagueFeesSaga() {
  yield* takeEvery("LEAGUE/FEES/FETCH", function* (action) {

    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v4/leagues/fees/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'FEES/SET',
          fees: result.fees
        });
        yield put({
          type: 'FEES/TYPE/SET',
          feeTypes: result.feeTypes
        });
      } else {
        yield put({
          type: 'LEAGUE/FEES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LEAGUE/FEES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getLeaguesAdminSaga() {
  yield* takeEvery("LEAGUES/ADMIN/FETCH", function* (action) {

    try {
      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v4/leagues/admin`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      });

      if (result && result.success) {
        yield put({
          type: 'LEAGUES/SET',
          leagues: result.leagues
        });
      } else {
        yield put({
          type: 'LEAGUES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LEAGUES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getLeaguesForRegistrationSaga() {
  yield* takeEvery("LEAGUES/REGISTRATION/FETCH", function* (action) {

    try {
      const result = yield call(function (term) {
        const url = _.compact([config.apiEndpoint, 'api/v4/leagues', term]).join('/');

        return request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.term);

      if (result && result.success) {
        yield put({
          type: 'LEAGUES/SET',
          leagues: result.leagues
        });

      } else {
        yield put({
          type: 'LEAGUES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LEAGUES/FETCH_FAILED',
        e
      });
    }
  });
}

//LEAGUES/PAYMENTS/FETCH
export function* getLeaguesForPaymentsSaga() {
  yield* takeEvery("LEAGUES/PAYMENTS/FETCH", function* (action) {

    try {
      const result = yield call(function () {
        const url = _.compact([config.apiEndpoint, 'api/v4/family/leagues']).join('/');

        return request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      });

      if (result && result.success) {
        yield put({
          type: 'LEAGUES/SET',
          leagues: result.leagues
        });

      } else {
        yield put({
          type: 'LEAGUES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LEAGUES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getLeagueSaga() {
  yield* takeEvery("LEAGUE/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague) {
        const url = [config.apiEndpoint, 'api/v4/leagues', idLeague].join('/');

        return request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague);

      if (result && result.success) {
        yield put({
          type: 'LEAGUE/SET',
          league: result.league
        });

      } else {
        yield put({
          type: 'LEAGUE/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LEAGUE/FETCH_FAILED',
        e
      });
    }
  });
}

//
export function* getLeagueNonSecureSaga() {
  yield* takeEvery("LEAGUE/NON_SECURE/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague) {
        const url = [config.apiEndpoint, 'api/v4/leagues/non-secure', idLeague].join('/');

        return request.get(url)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague);

      if (result && result.success) {
        yield put({
          type: 'LEAGUE/SET',
          league: result.league
        });

      } else {
        yield put({
          type: 'LEAGUE/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'LEAGUE/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getFamilyRegistrationSaga() {
  yield* takeEvery("FAMILY/REGISTRATION/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague) {
        const url = _.compact([config.apiEndpoint, 'api/v6/registration/family', idLeague]).join('/');

        return request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague);

      if (result && result.success) {
        result.registration.forEach(u => humanize_user(u));
        yield put({
          type: 'FAMILY/REGISTRATION/SET',
          users: result.registration
        });

      } else {
        yield put({
          type: 'FAMILY/REGISTRATION/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'FAMILY/REGISTRATION/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getTeamRegistrationSaga() {
  yield* takeEvery("TEAM/REGISTRATION/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague) {
        const url = _.compact([config.apiEndpoint, 'api/v6/registration/teams', idLeague]).join('/');

        return request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague);

      if (result && result.success) {
        // Decrypt
        result.registration.forEach(u => humanize_user(u));
        //
        yield put({
          type: 'TEAM/REGISTRATION/SET',
          teams: result.registration
        });

      } else {
        yield put({
          type: 'TEAM/REGISTRATION/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'TEAM/REGISTRATION/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getRolesByLeagueSaga() {
  yield* takeEvery("LEAGUE/ROLES/FETCH", function* (action) {

    try {
      const result = yield call(function (idLeague) {

        const url = `${config.apiEndpoint}/api/v4/leagues/roles/${idLeague}`;

        return request.get(url)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action.idLeague);

      if (result && result.success) {

        yield put({
          type: 'ROLES/SET',
          roles: result.roles
        });

      } else {
        yield put({
          type: 'ROLES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getDOBsByLeagueSaga() {
  yield* takeEvery("LEAGUE/DOB/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague, letter }) => {

        return request.get(`${config.apiEndpoint}/api/v4/leagues/dob/${idLeague}/${letter}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { users } = result;
        yield put({ type: 'USERS/SET', users });
      } else {
        yield put({
          type: 'ROLES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getValidationsByLeagueAndTypeSaga() {
  yield* takeEvery("LEAGUE/VALIDATIONS/TYPE/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague, letter, validation_type }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/types/${idLeague}/${validation_type}/${letter}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { users } = result;
        yield put({ type: 'USERS/SET', users });
      } else {
        yield put({
          type: 'ROLES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* getValidationsBySeasonAndTypeSaga() {
  yield* takeEvery("SEASON/VALIDATIONS/TYPE/FETCH", function* (action) {
    try {

      const result = yield call(({ idSeason, letter, validation_type }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/types_by_season/${idSeason}/${validation_type}/${letter}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);


      if (result && result.success) {
        const { users } = result;

        yield put({ type: 'USERS/SET', users });
      } else {
        yield put({
          type: 'ROLES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}


//LEAGUE/VALIDATIONS/FETCH
export function* getValidationsByLeagueSaga() {
  yield* takeEvery("LEAGUE/VALIDATIONS/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/by_league/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'VALIDATIONS/SET',
          validations: result.validations
        });
        yield put({
          type: 'PROCESSES/SET', processes: result.processes
        })

      } else {
        yield put({
          type: 'LEAGUE/VALIDATIONS/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'LEAGUE/VALIDATIONS/FETCH_FAILED',
        e
      });
    }
  });
}

// LEAGUE/DOB/VALIDATE
// Optimistic
export function* validateDOBSaga() {
  yield* takeEvery("LEAGUE/DOB/VALIDATE", function* (action) {
    try {
      yield call(({ idUser, idLeague, dob }) => {
        return request.post(`${config.apiEndpoint}/api/v4/leagues/dob`)
          .send({ idUser, idLeague, dob })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}

export function* unvalidateDOBSaga() {
  yield* takeEvery("LEAGUE/DOB/UNVALIDATE", function* (action) {
    try {
      yield call(({ idUser, idLeague }) => {
        return request.del(`${config.apiEndpoint}/api/v4/leagues/dob/${idUser}/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}

// LEAGUE/DOB/VALIDATE
// Optimistic
export function* validateDOBDocumentSaga() {
  yield* takeEvery("LEAGUE/DOB/DOCUMENT/VALIDATE", function* (action) {
    try {
      yield call(({ user }) => {
        return request.patch(`${config.apiEndpoint}/api/v5/validations/validate`)
          .send({ user })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      let idLeague = action.user.IdLeague;
      yield put({ type: 'VALIDATIONS/SUMMARY/FETCH', idLeague });
      // HERE

    } catch (e) {
      yield put({
        type: 'LEAGUE/DOB/DOCUMENT/VALIDATE/FAILED',
        e
      });
    }
  });
}

// Optimistic
export function* validateDocumentSaga() {
  yield* takeEvery("LEAGUE/DOCUMENT/VALIDATE", function* (action) {
    try {
      yield call(({ user, validation_type }) => {
        return request.patch(`${config.apiEndpoint}/api/v5/validations/validate/${validation_type}`)
          .send({ user })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      let idLeague = action.user.IdLeague;
      yield put({ type: 'VALIDATIONS/SUMMARY/FETCH', idLeague });
      // HERE

    } catch (e) {
      yield put({
        type: 'LEAGUE/DOB/DOCUMENT/VALIDATE/FAILED',
        e
      });
    }
  });
}

export function* validateDocumentSeasonSaga() {
  yield* takeEvery("LEAGUE/DOCUMENT/SEASON/VALIDATE", function* (action) {
    try {
      yield call(({ user, validation_type }) => {
        return request.patch(`${config.apiEndpoint}/api/v5/validations/validate/${validation_type}`)
          .send({ user })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      let idSeason = action.user.IdSeason;
      yield put({ type: 'VALIDATIONS/SEASON_SUMMARY/TYPE/FETCH', idSeason, validation_type: action.validation_type });
      // HERE

    } catch (e) {
      yield put({
        type: 'LEAGUE/DOB/DOCUMENT/VALIDATE/FAILED',
        e
      });
    }
  });
}

// LEAGUE/DOB/VALIDATE
// Optimistic
export function* unvalidateDOBDocumentSaga() {
  yield* takeEvery("LEAGUE/DOB/DOCUMENT/UNVALIDATE", function* (action) {
    try {
      yield call(({ user }) => {
        return request.patch(`${config.apiEndpoint}/api/v5/validations/unvalidate`)
          .send({ user })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      let idLeague = action.user.IdLeague;
      yield put({ type: 'VALIDATIONS/SUMMARY/FETCH', idLeague });
      // HERE

    } catch (e) {
      yield put({
        type: 'LEAGUE/DOB/DOCUMENT/VALIDATE/FAILED',
        e
      });
    }
  });
}

// LEAGUE/DOB/VALIDATE
// Optimistic
export function* unvalidateDocumentSaga() {
  yield* takeEvery("LEAGUE/DOCUMENT/UNVALIDATE", function* (action) {
    try {
      yield call(({ user, validation_type }) => {
        return request.patch(`${config.apiEndpoint}/api/v5/validations/unvalidate/${validation_type}`)
          .send({ user })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      let idLeague = action.user.IdLeague;
      yield put({ type: 'VALIDATIONS/SUMMARY/FETCH', idLeague });
      // HERE

    } catch (e) {
      yield put({
        type: 'LEAGUE/DOB/DOCUMENT/VALIDATE/FAILED',
        e
      });
    }
  });
}

export function* unvalidateDocumentSeasonSaga() {
  yield* takeEvery("LEAGUE/DOCUMENT/SEASON/UNVALIDATE", function* (action) {
    try {
      yield call(({ user, validation_type }) => {
        return request.patch(`${config.apiEndpoint}/api/v5/validations/unvalidate/${validation_type}`)
          .send({ user })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      let idSeason = action.user.IdSeason;
      yield put({ type: 'VALIDATIONS/SEASON_SUMMARY/TYPE/FETCH', idSeason, validation_type: action.validation_type });
      // HERE

    } catch (e) {
      yield put({
        type: 'LEAGUE/DOB/DOCUMENT/VALIDATE/FAILED',
        e
      });
    }
  });
}

// /validateDOBDocumentSaga
export function* validationsSummaryFetch() {
  yield* takeEvery("VALIDATIONS/SUMMARY/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/summary/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { totals, validations, spread, league_summary } = result;
        let validations_summary = { totals, validations, spread, league_summary }
        yield put({ type: 'VALIDATIONS/SUMMARY/SET', validations_summary });
      } else {
        yield put({
          type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
        e
      });
    }
  });
}

// /validateDOBDocumentSaga
export function* validationsSummaryByTypeSeasonFetch() {
  yield* takeEvery("VALIDATIONS/SEASON_SUMMARY/TYPE/FETCH", function* (action) {
    try {
      const result = yield call(({ idSeason, validation_type }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/summary/by_season_type/${validation_type}/${idSeason}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {

        const { spread } = result;
        yield put({ type: 'SPREAD/SET', spread });
      } else {
        yield put({
          type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
        e
      });
    }
  });
}

// /validateDOBDocumentSaga
export function* validationsSummaryByTypeFetch() {
  yield* takeEvery("VALIDATIONS/SUMMARY/TYPE/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague, validation_type }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/summary/${validation_type}/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { spread } = result;
        yield put({ type: 'SPREAD/SET', spread });
      } else {
        yield put({
          type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'VALIDATIONS/SUMMARY/FETCH_FAILED',
        e
      });
    }
  });
}

//LEAGUE/PCV/FETCH
export function* getPCVsByLeagueSaga() {
  yield* takeEvery("LEAGUE/PCV/FETCH", function* (action) {

    try {
      const result = yield call(({ idLeague, letter }) => {
        return request.get(`${config.apiEndpoint}/api/v4/leagues/pcv/${idLeague}/${letter}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        const { users } = result;
        yield put({ type: 'USERS/SET', users });
      } else {
        yield put({
          type: 'USERS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'USERS/FETCH_FAILED',
        e
      });
    }
  });
}

// LEAGUE/PCV/VALIDATE
// Optimistic
export function* validatePCVSaga() {
  yield* takeEvery("LEAGUE/PCV/VALIDATE", function* (action) {
    try {
      yield call(({ idUser, idLeague }) => {
        return request.post(`${config.apiEndpoint}/api/v4/leagues/pcv`)
          .send({ idUser, idLeague })
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}


export function* unvalidatePCVSaga() {
  yield* takeEvery("LEAGUE/PCV/UNVALIDATE", function* (action) {
    try {
      yield call(({ idUser, idLeague }) => {
        return request.del(`${config.apiEndpoint}/api/v4/leagues/pcv/${idUser}/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'ROLES/FETCH_FAILED',
        e
      });
    }
  });
}



// TOURNAMENT/TOURNAMENT_SEASONS
export function* getTournamentSeasonsSaga() {
  yield* takeEvery("TOURNAMENT/TOURNAMENT_SEASONS/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v4/leagues/tournamentSeasons/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({
          type: 'TOURNAMENT/TOURNAMENT_SEASONS/SET',
          tournamentSeasons: result.tournamentSeasons
        });
        yield put({
          type: 'LEAGUE_POINTS/SET',
          leaguePoints: result.points
        });
      } else {
        yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/FETCH_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/FETCH_FAILED', e });
    }
  });
}

// TOURNAMENT/TOURNAMENT_SEASONS
export function* createTournamentSeasonSaga() {
  yield* takeEvery("TOURNAMENT/TOURNAMENT_SEASONS/CREATE", function* (action) {
    try {
      const result = yield call(({ idLeague, entity }) => {
        return request.post(`${config.apiEndpoint}/api/v4/leagues/tournamentSeasons/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send(entity)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({
          type: 'TOURNAMENT/TOURNAMENT_SEASONS/FETCH',
          idLeague: action.idLeague
        });
      } else {
        yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/CREATE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/CREATE_FAILED', e });
    }
  });
}

// TOURNAMENT/TOURNAMENT_SEASONS
export function* updateTournamentSeasonSaga() {
  yield* takeEvery("TOURNAMENT/TOURNAMENT_SEASONS/UPDATE", function* (action) {
    try {
      const result = yield call(({ entity }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/leagues/tournamentSeasons/${entity.IdTournamentSeason}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send(entity)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({
          type: 'TOURNAMENT/TOURNAMENT_SEASONS/FETCH',
          idLeague: action.entity.IdLeague
        });
      } else {
        yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/UPDATE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/UPDATE_FAILED', e });
    }
  });
}

// TOURNAMENT/TOURNAMENT_SEASONS
export function* deleteTournamentSeasonSaga() {
  yield* takeEvery("TOURNAMENT/TOURNAMENT_SEASONS/DELETE", function* (action) {
    try {
      const result = yield call(({ idTournamentSeason }) => {
        return request.del(`${config.apiEndpoint}/api/v4/leagues/tournamentSeasons/${idTournamentSeason}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        // Optimistic
      } else {
        yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/DELETE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/DELETE_FAILED', e });
    }
  });
}

//LEAGUE_POINTS/UPDATE
export function* updateLeaguePointsSaga() {
  yield* takeEvery("LEAGUE_POINTS/UPDATE", function* (action) {
    try {
      const result = yield call(({ idLeague, points, idTournamentType, idTournamentSeason }) => {
        return request.post(`${config.apiEndpoint}/api/v4/leagues/leaguePoints/${idLeague}/${idTournamentType}/${idTournamentSeason}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({ points })
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/FETCH', idLeague: action.idLeague });
        //yield put({ type: 'LEAGUE_POINTS/SET', leaguePoints: result.points });
      } else {
        yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/DELETE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'TOURNAMENT/TOURNAMENT_SEASONS/DELETE_FAILED', e });
    }
  });
}

//LEAGUE/IMAGE/FETCH
export function* fetchLeagueImageSaga() {
  yield* takeEvery("LEAGUE/IMAGE/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v4/leagues/image/${idLeague}`)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({ type: 'LEAGUE/IMAGE/SET', leagueImage: result.leagueImage || null });
      }
    } catch (e) {
      yield put({ type: 'LEAGUE/IMAGE/FETCH_FAILED', e });
    }
  });
}

//LEAGUE/IMAGE/UPDATE
export function* updateLeagueImageSaga() {
  yield* takeEvery("LEAGUE/IMAGE/UPDATE", function* (action) {
    try {
      const result = yield call(({ idLeague, leagueImage }) => {
        return request.patch(`${config.apiEndpoint}/api/v4/leagues/image/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({ leagueImage })
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({ type: 'LEAGUE/IMAGE/FETCH', idLeague: action.idLeague });
        yield put({ type: 'LEAGUE/FORCE/IMAGE/UPDATE', idLeague: action.idLeague });
      }
    } catch (e) {
      yield put({ type: 'LEAGUE/IMAGE/UPDATE_FAILED', e });
    }
  });
}

// RANKINGS
//RANKING/TEAMS/FETCH
export function* rankingTeamsFetchSaga() {
  yield* takeEvery("RANKING/TEAMS/FETCH", function* (action) {
    try {
      const result = yield call(({ idTournamentSeason }) => {
        return request.get(`${config.apiEndpoint}/api/v4/rankings/teams/${idTournamentSeason}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({ type: 'TEAMS/SET', teams: result.teams });
      }
    } catch (e) {
      yield put({ type: 'RANKING/TEAMS/FETCH_FAILED', e });
    }
  });
}

//LEAGUE/DIVISION/TEMPLATES/FETCH
export function* divisionTemplatesFetch() {
  yield* takeEvery("LEAGUE/DIVISION/TEMPLATES/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague }) => {

        return request.get(`${config.apiEndpoint}/api/v4/leagues/division_template/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({ type: 'TEMPLATES/SET', templates: result.templates });
      }
    } catch (e) {
      yield put({ type: 'RANKING/TEAMS/FETCH_FAILED', e });
    }
  });
}

// ADS

export function* addCampaignSaga() {
  yield* takeEvery("AD/CAMPAIGN/CREATE", function* (action) {

    yield put({
      type: 'CAMPAIGNS/ADD/LAZY',
      campaign: action.campaign
    });

    try {
      const result = yield call(({ campaign }) => {
        return request.post(`${config.apiEndpoint}/api/v5/ads`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send(campaign)
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({
          type: 'AD/FETCH'
        });
      } else {
        yield put({ type: 'AD/CAMPAIGN/CREATE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'AD/CAMPAIGN/CREATE_FAILED', e });
    }
  });
}

export function* fetchCampaings() {
  yield* takeEvery("CAMPAIGNS/FETCH", function* (action) {
    try {
      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v5/ads`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({
          type: 'CAMPAIGNS/SET', campaigns: result.campaigns
        });
      } else {
        yield put({ type: 'AD/CAMPAIGN/CREATE_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'AD/CAMPAIGN/CREATE_FAILED', e });
    }
  });
}

//USER/LEAGUE/FETCH
export function* fetchUserAndAll() {
  yield* takeEvery("USER/LEAGUE/FETCH", function* (action) {
    try {
      const result = yield call(({ idUser, idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v5/validations/user/${idUser}/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({ type: 'USER/SET', user: result.user });
      } else {
        yield put({ type: 'USER/LEAGUE/FETCH_FAILED', result });
      }

    } catch (e) {
      yield put({ type: 'USER/LEAGUE/FETCH_FAILED', e });
    }
  });
}

//USER/LEAGUE/FETCH
export function* fetchUserNotesSaga() {
  yield* takeEvery("LEAGUE/USER/NOTES/FETCH", function* (action) {
    try {
      const result = yield call(({ idUser, idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v5/leagues/notes/by_user/${idUser}/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({ type: 'NOTES/SET', notes: result.notes });
      } else {
        yield put({ type: 'LEAGUE/USER/NOTES/FETCH_FAILED', result });
      }

    } catch (e) {
      yield put({ type: 'LEAGUE/USER/NOTES/FETCH_FAILED', e });
    }
  });
}

export function* addUserNotesSaga() {
  yield* takeEvery("LEAGUE/USER/NOTES/ADD", function* (action) {
    try {
      const result = yield call(({ note }) => {
        return request.post(`${config.apiEndpoint}/api/v5/leagues/notes/by_user/${note.IdUser}/${note.IdLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({ note })
          .then((data) => JSON.parse(data.text));
      }, action);
      const { note } = action;
      if (result && result.success) {
        yield put({ type: 'LEAGUE/USER/NOTES/FETCH', idUser: note.IdUser, idLeague: note.IdLeague });
      } else {
        yield put({ type: 'LEAGUE/USER/NOTES/ADD_FAILED', result });
      }

    } catch (e) {
      yield put({ type: 'LEAGUE/USER/NOTES/ADD_FAILED', e });
    }
  });
}

export function* deleteUserNotesSaga() {
  yield* takeEvery("LEAGUE/USER/NOTES/DEL", function* (action) {
    try {
      const result = yield call(({ note }) => {
        return request.del(`${config.apiEndpoint}/api/v5/leagues/notes/by_user/${note.IdLeagueAdminNote}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);
      const { note } = action;
      if (result && result.success) {
        yield put({ type: 'LEAGUE/USER/NOTES/FETCH', idUser: note.IdUser, idLeague: note.IdLeague });
      } else {
        yield put({ type: 'LEAGUE/USER/NOTES/DEL_FAILED', result });
      }

    } catch (e) {
      yield put({ type: 'LEAGUE/USER/NOTES/DEL_FAILED', e });
    }
  });
}

//REGISTRATIONS/PENDING/FETCH
export function* fetchPendingRegistrations() {
  yield* takeEvery("REGISTRATIONS/PENDING/FETCH", function* (action) {

    try {

      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v6/registration/pending`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        // Decrypt        
        result.registrations.forEach(o => humanize_user(o));
        //
        yield put({
          type: 'REGISTRATIONS/SET',
          registrations: result.registrations
        });
      } else {
        yield put({
          type: 'REGISTRATIONS/PENDING/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'REGISTRATIONS/PENDING/FETCH_FAILED',
        e
      });
    }
  });

}

//VALIDATIONS/PENDING/FETCH
export function* fetchPendingValidationsSaga() {
  yield* takeEvery("VALIDATIONS/PENDING/FETCH", function* (action) {

    try {

      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v6/validations/by_family`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        // Decrypt
        result.validations.forEach(({ user }) => humanize_user(user));
        //
        yield put({
          type: 'VALIDATIONS/SET',
          validations: result.validations
        });
      } else {
        yield put({
          type: 'REGISTRATIONS/PENDING/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'REGISTRATIONS/PENDING/FETCH_FAILED',
        e
      });
    }
  });

}


export function* rejectPendingRegistration() {
  yield* takeEvery("REGISTRATIONS/PENDING/REJECT", function* (action) {

    try {

      const result = yield call(({ IdPendingRegistration }) => {
        return request.delete(`${config.apiEndpoint}/api/v5/registration/reject/${IdPendingRegistration}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({ type: 'REGISTRATIONS/PENDING/FETCH' });
      } else {
        yield put({
          type: 'REGISTRATIONS/PENDING/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'REGISTRATIONS/PENDING/FETCH_FAILED',
        e
      });
    }
  });

}

// COUPONS
export function* fetchCouponsByLeagueSaga() {
  yield* takeEvery("LEAGUE/COUPONS/FETCH", function* (action) {

    try {

      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v4/coupons/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'COUPONS/SET',
          coupons: result.coupons
        });
      } else {
        yield put({
          type: 'LEAGUE/COUPONS/FETCH_FAILED',
          result
        });
      }

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });

}

export function* requestPictureReplacementSaga() {
  yield* takeEvery("USER/PICTURE/REPLACE/CREATE", function* (action) {

    try {
      yield call(({ idLeague, idUser, idSeason }) => {
        return request.post(`${config.apiEndpoint}/api/v4/users/request/replacement/${idUser}/${idLeague}`)
          .set('season', idSeason)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({})
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* enforcePictureReplacementSaga() {
  yield* takeEvery("USER/PICTURE/REPLACE/ENFORCE", function* (action) {

    try {
      yield call(({ idSeason, idUser }) => {
        return request.post(`${config.apiEndpoint}/api/v6/seasons/require/photo_replacement/${idUser}/${idSeason}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send({})
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* removePictureReplacementSaga() {
  yield* takeEvery("USER/PICTURE/REPLACE/DELETE", function* (action) {

    try {
      yield call(({ idLeague, idUser, idSeason }) => {
        return request.del(`${config.apiEndpoint}/api/v4/users/request/replacement/${idUser}/${idLeague}`)
          .set('season', idSeason)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* denyPictureReplacementSaga() {
  yield* takeEvery("USER/PICTURE/REPLACE/DENY", function* (action) {

    try {
      yield call(({ idLeague, idUser, idSeason }) => {
        return request.patch(`${config.apiEndpoint}/api/v6/seasons/deny/photo_replacement/${idUser}/${idSeason}`)          
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });
}

//

// MEMBERSHIP PROGRAMS

export function* getMembershipProgramsSaga() {
  yield* takeEvery("LEAGUE/MEMBERSHIP_PROGRAMS/FETCH", function* (action) {
    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v5/leagues/membership_programs/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => JSON.parse(data.text));
      }, action);

      if (result && result.success) {
        yield put({
          type: 'LEAGUE/MEMBERSHIP_PROGRAMS/SET',
          membership_programs: result.membership_programs
        });
      } else {
        yield put({ type: 'LEAGUE/MEMBERSHIP_PROGRAMS/FETCH_FAILED', result });
      }
    } catch (e) {
      yield put({ type: 'LEAGUE/MEMBERSHIP_PROGRAMS/FETCH_FAILED', e });
    }
  });
}

export function* createMembershipProgramSaga() {
  yield* takeEvery("LEAGUE/MEMBERSHIP_PROGRAM/CREATE", function* (action) {

    try {
      const result = yield call(({ membership_program }) => {
        return request.post(`${config.apiEndpoint}/api/v5/leagues/membership_program/${membership_program.IdLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send(membership_program)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'LEAGUE/MEMBERSHIP_PROGRAMS/FETCH',
          idLeague: action.membership_program.IdLeague
        });
      } else {
        yield put({ type: 'LEAGUE/MEMBERSHIP_PROGRAMS/CREATE_FAILED', result });
      }

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* updateMembershipProgramSaga() {
  yield* takeEvery("LEAGUE/MEMBERSHIP_PROGRAM/UPDATE", function* (action) {

    try {
      const result = yield call(({ membership_program }) => {
        return request.patch(`${config.apiEndpoint}/api/v5/leagues/membership_program/${membership_program.IdMembership}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .send(membership_program)
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'LEAGUE/MEMBERSHIP_PROGRAMS/FETCH',
          idLeague: action.membership_program.IdLeague
        });
      } else {
        yield put({ type: 'LEAGUE/MEMBERSHIP_PROGRAMS/CREATE_FAILED', result });
      }

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });
}

export function* deleteMembershipProgramSaga() {
  yield* takeEvery("LEAGUE/MEMBERSHIP_PROGRAM/DELETE", function* (action) {

    try {
      const result = yield call(({ IdMembership }) => {
        return request.del(`${config.apiEndpoint}/api/v5/leagues/membership_program/${IdMembership}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {
        yield put({
          type: 'LEAGUE/MEMBERSHIP_PROGRAMS/FETCH',
          idLeague: action.IdLeague
        });
      } else {
        yield put({ type: 'LEAGUE/MEMBERSHIP_PROGRAMS/CREATE_FAILED', result });
      }

    } catch (e) {
      yield put({
        type: 'LEAGUE/COUPONS/FETCH_FAILED',
        e
      });
    }
  });
}

//LEAGUE/CURRENCY/FETCH
export function* fetchLeagueCurrencySaga() {
  yield* takeEvery("LEAGUE/CURRENCY/FETCH", function* (action) {

    try {
      const result = yield call(({ idLeague }) => {
        return request.get(`${config.apiEndpoint}/api/v5/leagues/currency_code/${idLeague}`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      }, action);

      if (result && result.success) {        
        localStorage.setItem('sqwad.currency_code', result.currency_code);
      } 

    } catch (e) {
      
    }
  });
}

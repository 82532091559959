import React from 'react';
import { Animated } from 'react-animated-css';
import VideoListItem from './partials/VideoListItem';
import Loader from '../common/Loader';
import EditModal from '../common/modals/EditVideo';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon,
    FacebookIcon,

} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import './css.css';
import _ from 'lodash';
import fakeAuth from '../../fakeAuth';

class Videos extends React.Component {
    state = {
        bootstrapped: true,
        videos: null,
        editing: false,
        selectedVideo: null,
        copySuccess: false,
        mode: 1
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, selectedTeam, family_lastname } = this.props;

        setTitle && setTitle({
            main: 'Videos',
            sub: (selectedTeam && selectedTeam.Name) ? selectedTeam.Name : `${family_lastname}'s`
        });

        const { params } = this.props;
        selectedTeam && this.props.teamActions && this.props.teamActions.fetchTeamSeasons && this.props.teamActions.fetchTeamSeasons(params.idTeam);
        this.props.teamActions && this.props.teamActions.fetchVideos(params.idTeam || 1);
        this.props.familyActions && this.props.familyActions.getFamilySubscriptions();
    }
    componentWillReceiveProps = nextProps => {
        const { params } = this.props, { idTeam } = params, { videos, family_videos } = nextProps;        
        this.setState({
            videos: idTeam ? _.sortBy([...nextProps.videos || [], ...nextProps.family_videos], 'createDate').reverse() :
                _.sortBy([...nextProps.family_videos], 'createDate').reverse(),
            family_videos: _.chain(family_videos).filter(v => v.IS_FAMILY_VIDEO).sortBy('createDate').value().reverse(),
            team_videos: _.chain(family_videos).reject(v => v.IS_FAMILY_VIDEO).groupBy('teamName').values().value()
        })
    }
    //

    toggleEdit = () => this.setState({ editing: !this.state.editing });
    onEdit = video => this.setState({ editing: true, selectedVideo: video });
    onClick = video => { this.props.teamActions.setVideo(video); }
    onShare = video => { this.setState({ sharing: video, copySuccess: false }); }
    onDelete = () => {
        //
        const { sharing } = this.state, { params } = this.props;
        this.setState({ sharing: null, mode: 1 });
        // TODO: call api based on the privileges of the video
        if (sharing.idUser === fakeAuth.getUser().IdUser || sharing.idAccount === fakeAuth.getUser().IdFamily)
            this.props.teamActions && this.props.teamActions.deleteVideo(sharing, params.idTeam || 1);
        else
            this.props.teamActions && this.props.teamActions.archiveVideo(sharing.idVideo, params.idTeam || 1);
    }

    render() {
        const { videos = [], bootstrapped, editing, copySuccess, family_videos, team_videos = [], microTransac, mode } = this.state, { params, selectedTeam, family } = this.props;

        let shareUrl = `https://matchreports.thesqwad.com/highlight2/${(this.state.sharing || {}).idVideo}`;
        return (<Animated animateIn="fadeIn">

            {bootstrapped && videos && videos.length === 0 && (
                <div key="no-leagues" className="d-flex flex-col justify-content-center centered-message">
                    <h1 className="white align-self-center block text-center">There are no Videos registered {selectedTeam ? 'for this team' : 'for the LAST NAME family'}</h1>
                </div>
            )}

            {bootstrapped && videos && videos.length > 0 && (
                <div style={{ margin: 'auto' }} className="p-4">

                    <div className="w3-content mx-auto">
                        <h1>Family Videos</h1>
                        <div className="d-flex flex-wrap mt-4">
                            {family_videos.map((video, i) => <VideoListItem family={family} key={i} index={i}
                                video={video} idTeam={params.idTeam} onEdit={this.onEdit} onClick={this.onClick} onShare={() => this.onShare(video)} />)}
                        </div>
                    </div>

                    {team_videos.map((section, i) => {
                        return <section className="w3-content mx-auto mt-4" key={i}>
                            <hr />
                            <h1>{_.first(section).teamName}</h1>
                            <div className="d-flex flex-wrap mt-4">
                                {section.map((video, i) => <VideoListItem family={family} key={i} index={i}
                                    video={video} idTeam={params.idTeam} onEdit={this.onEdit} onClick={this.onClick} onShare={() => this.onShare(video)} />)}
                            </div>
                        </section>
                    })}

                </div>
            )}

            {!bootstrapped && <Loader key="loader" message={`Loading Videos`} />}

            {editing && <EditModal {...this.props} toggle={this.toggleEdit} isOpen={editing} video={this.state.selectedVideo}
                selectedTeam={selectedTeam} />}

            <Modal isOpen={this.state.sharing ? true : false} toggle={() => this.setState({ sharing: null })} style={{ width: 400 }}>
                <ModalBody className="p-4 bg-white text-center w-100 d-flex flex-column justify-content-center" >
                    <h4 className="mt-4">Share Video</h4>
                    <img src={`https://api.sportslogic.net/api/v5/qrcodes/video/${(this.state.sharing || {}).idVideo}`} style={{ width: 300 }} alt="QR_Code" className="align-self-center mb-4" />
                    <div className="d-flex align-self-center mb-4">

                        {/*<TwitterShareButton className="mr-2 align-self-start" title="Video" url={shareUrl}>
                            <TwitterIcon size={40} round={true} />
                </TwitterShareButton>*/}
                        <WhatsappShareButton className="mr-2 align-self-start" title={`Check out this video`} url={shareUrl}>
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton className="mr-2 align-self-start" quote={`Check out this video`} url={shareUrl}>
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <div className="w-auto d-flex flex-column">
                            <input className={`${copySuccess ? 'bg-success' : ''} form-control text-input w-auto`} disabled="disabled" ref={(i) => this.txtClipboard = i} value={shareUrl} />
                            {this.state.copySuccess && <span className="black font-10">Link copied in your clipboard!</span>}
                        </div>

                        <CopyToClipboard text={shareUrl}
                            onCopy={() => this.setState({ copySuccess: true })}>
                            <button className="btn btn-link align-self-start"><i className="fas fa-clipboard blue" /></button>
                        </CopyToClipboard>
                    </div>

                </ModalBody>
                {!microTransac && mode === 1 && this.state.sharing && (<ModalFooter className="d-flex flex-row">
                    <button className="btn btn-link align-self-center red" onClick={() => this.setState({ mode: 4 })}>{this.state.sharing.IS_FAMILY_VIDEO ? 'Delete' : 'Archive'}</button>
                </ModalFooter>)}
                {mode === 4 && <ModalFooter className="d-flex flex-row">
                    <button className="btn btn-link align-self-center" onClick={() => this.setState({ mode: 1 })}>Cancel</button>
                    <button className="btn btn-error align-self-center ml-auto" onClick={this.onDelete}>Yes, confirm</button>
                </ModalFooter>}
            </Modal>

        </Animated>)
    }
}

export default Videos;
import React from 'react';
import { render } from 'react-dom';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';

import store from './store';

// CSS
import './css/layout.css';
import './css/fonts.css';
import './css/buttons.css';
import './css/colors.css';
import './css/helpers.css';
import './css/bootstrap4/css/bootstrap.css';
import './css/animations.css';
import 'react-select/dist/react-select.css';

// Apps
import App_Admin from './apps/App.Admin';
import App_CreateAccount from './apps/App.CreateAccount';
import App_Disclaimer from './apps/App.Disclaimer';
import App_DOB from './apps/App.DOB';
import App_Leads from './apps/App.Leads';
import App_Login from './apps/App.Login';
import App_Login2 from './apps/App.Login2';
import App_Payments from './apps/App.Payments';
import App_PCV from './apps/App.PCV';
import App_Privacy from './apps/App.Privacy';
import App_Profile from './apps/App.Profile';
import App_Rankings from './apps/App.Rankings';
import App_Recover from './apps/App.Recover';
import App_Registration from './apps/App.Registration';
import App_Reset from './apps/App.Reset';
import App_Roster from './apps/App.Roster';
import App_Season from './apps/App.Season';
import App_Signature from './apps/App.Signature';
import App_SQWAD from './apps/App.SQWAD';
import App_Terms from './apps/App.Terms';
import App_Unsubscribe from './apps/App.Unsubscribe';
import App_Upgrades from './apps/App.Upgrades';
import App_User from './apps/App.User';
import App_Waivers from './apps/App.Waivers';

// Common
import Soon from './components/Soon';

// Childs
import Ads from './components/ads';
import AccountingReport from './components/seasons/reports/accounting';
import AutoPayReport from './components/seasons/reports/auto_pay';
import Awards from './components/seasons/administration/awards';
import Brackets from './components/seasons/manage_schedule/brackets';
import Brochures from './components/sqwad/Brochures';
import Build_Upgrades from './components/family/upgrades/Build';
import Build_Type from './components/seasons/manage_schedule/schedule_manager/build_type';
import Calendar from './components/seasons/manage_schedule/calendar';
import Checkout from './components/family/registration/Checkout';
import CoachAssign from './components/seasons/team_management/coach_assign/Index';
import Contact from './components/family/contact';
import Coupons from './components/coupons/index';
import CreateAccount from './components/create_account/index2';
import Credentials from './components/family/credentials';
import CustomQuestions from './components/seasons/administration/custom_questions';
import DOB from './components/dob';
import Divisions from './components/seasons/administration/divisions_management';
import EditTeams from './components/seasons/team_management/edit_teams';
import FamilySubscriptions from './components/family/subscriptions';
import FieldsManager from './components/seasons/manage_schedule/fields_manager/';
import FieldLayouts from './components/seasons/manage_schedule/field_layout/';
import FlightManager from './components/seasons/manage_schedule/flight_manager';
import GameCards from './components/seasons/manage_schedule/game_cards';
import GamesReport from './components/seasons/reports/scheduled_games';
import JerseyNumbers from './components/family/roster/JerseyNumbers';
import League from './components/leagues/League';
import Leagues from './components/leagues/Leagues';
import MessageCenter from './components/message_center/';
import Organization from './components/organization';
import OrganizationFees from './components/organization/fees';
import OrganizationDashboard from './components/organization/dashboard';
import OrganizationEarnings from './components/organization/earnings';
import Pairings from './components/seasons/manage_schedule/schedule_manager/pairings';
import PaymentLeagues from './components/family/payments/Leagues';
import PaymentSeasons from './components/family/payments/Seasons';
import PCV from './components/pcv';
import PCVReport from './components/seasons/reports/pcv';
import DonationsReport from './components/seasons/reports/donations';
import PendingRegistration from './components/family/registration/PendingRegistration';
import PlayerCards from './components/seasons/administration/player_cards';
import Points from './components/seasons/manage_schedule/points';
import PrintRoster from './components/seasons/administration/print_roster';
import PrintAwards from './components/seasons/administration/print_awards';
import Profile from './components/family/profile';
import RankingsPublic from './components/public/rankings';
import RankingTeams from './components/rankings';
import RegistrationFees from './components/seasons/administration/registration_fees';
import RegistrationLeagues from './components/family/registration/Leagues';
import RegistrationReport from './components/seasons/reports/registration';
import RegistrationSeasons from './components/family/registration/Seasons';
import RevenueReports from './components/seasons/reports/revenue';
import Roster from './components/family/roster';
import RosterAddExisting from './components/family/roster/AddExisting';
import RosterAddNew from './components/family/roster/AddNew';
import RosterWizard from './components/family/roster/wizard';
import RosterSeasons from './components/family/roster/Seasons';
import RosterTeam from './components/family/roster/Roster_New';
import RosterLock from './components/seasons/team_management/lock_roster';
import RegistrationSnapshot from './components/seasons/reports/snapshot';
import SayCards from './components/seasons/administration/player_cards/say';
import Season from './components/seasons/administration/season';
import SeasonValidationsMenu from './components/seasons/validations/menu';
import SeasonValidations from './components/seasons/validations/index';
import ScheduleManager from './components/seasons/manage_schedule/schedule_manager';
import ScheduleMasterView from './components/seasons/manage_schedule/schedule_master_view';
import ScoreEntry from './components/seasons/manage_schedule/score_entry';
import SeasonDashboard from './components/seasons/dashboard';
import SetRosters from './components/seasons/team_management/set_rosters/Index';
import Shirts from './components/seasons/administration/shirts';
import ShirtsReport from './components/seasons/reports/shirts';
import Slots from './components/seasons/manage_schedule/slots';
import Sqwad from './components/sqwad';
import SqwadBrochure from './components/sqwad/brochure/index';
import SqwadTeam from './components/sqwad/Team';
import SqwadClips from './components/sqwad/Clips';
import SqwadHighlights from './components/sqwad/Highlights';
import SqwadUpload from './components/sqwad/Upload';
import SqwadFamily from './components/sqwad/Family';
import SqwadFamilyProfile from './components/sqwad/CollegeProfile';
import SqwadVideos from './components/sqwad/Videos';
import SqwadVideo from './components/sqwad/Video';
import SqwadTest from './components/sqwad/Studio';
import TeamPayments from './components/family/payments/Team';
import TeamsAdmins from './components/seasons/reports/admins_registration';
import TeamsAdminsRegistration from './components/seasons/reports/teams';
import TeamsRegistration from './components/seasons/reports/roster';
import TeamProfile from './components/profile/Team';
import TeamRegistration from './components/family/registration/Team';
import TravelRosters from './components/seasons/administration/travel';
import Tryouts from './components/seasons/team_management/tryouts';
import UserModules from './components/UserModules';
import UserPayments from './components/family/payments/User';
import UserValidations from './components/validations/user';
import UnsubscribeEmail from './components/family/contact/unsubscribe_emails';
import UserProfile from './components/profile/User';
import UserProfileTeam from './components/profile/UserTeam';
import UserRegistration from './components/family/registration/User';
import CampRegistration from './components/family/registration/Camp';
import Validations from './components/validations';
import ValidationsSnapshot from './components/validations/snapshot';
import Waitlists from './components/seasons/team_management/waitlists';
import Waivers from './components/sqwad/Waivers';

// Help
import fakeAuth from './fakeAuth';
import ProfilePicture from './components/seasons/validations/profile_picture';
import OrganizationSessions from './components/organization/sessions';
import MasterTransactions from './components/seasons/reports/master_transactions';
import ScoutProfile from './components/profile/Scout';
import TeamsRoster from './components/seasons/reports/team_roster';


const history = syncHistoryWithStore(browserHistory, store)

function requireAuth(nextState, replace) {
  if (!fakeAuth.isAuthenticated()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
    return;
  }
}

function requireAdmin(nextState, replace) {
  if (!fakeAuth.isAuthenticated() || !fakeAuth.isAdmin()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        { /* SITEMAP? */}
        <Router history={history}>
          { /* Family Site */}
          <Route path="/" component={App_User} onEnter={requireAuth}>
            <IndexRoute component={UserModules} />
            <Route path="/family" component={UserModules} />
            <Route path="/recruit/:mode" component={UserModules} />
          </Route>
          { /*  Leads */}
          <Route path="/leads" component={App_Leads} />
          { /* Waiver Template */}
          <Route path="/waivers/:idLeague" component={App_Waivers} />
          <Route path="/waivers/:idLeague/:idUser" component={App_Waivers} />
          <Route path="/disclaimer/:idOrder/:idUser" component={App_Disclaimer} />
          <Route path="/disclaimer/:idSeason" component={App_Disclaimer} />
          <Route path="/privacy" component={App_Privacy} />
          <Route path="/terms" component={App_Terms} />
          { /* Signature plugin test */}
          <Route path='/signature' component={App_Signature} />
          { /* Create Account */}
          <Route path="/create_account" component={App_CreateAccount}>
            <IndexRoute component={CreateAccount} />
            <Route path="/create_account/:idLeague" component={CreateAccount} />
          </Route>
          { /* Login */}
          <Route path="/login2" component={App_Login} />
          <Route path="/login2/:idLeague" component={App_Login} />
          <Route path="/login" component={App_Login2} />
          <Route path="/login/:idLeague" component={App_Login2} />
          <Route path='/recover' component={App_Recover} />
          <Route path='/reset/:token' component={App_Reset} />
          { /* Rostering */}
          {/* SQWAD */}
          <Route path="/sqwad" component={App_SQWAD} onEnter={requireAuth}>
            <IndexRoute component={Sqwad} />
            <Route path="brochures" component={Brochures} />
          </Route>
          <Route path="/sqwad/family" component={App_SQWAD}>
            <IndexRoute component={SqwadFamily} />

            <Route path="dashboard" component={SqwadFamily} />
            <Route path="videos" component={SqwadVideos} />
            <Route path="video/:idVideo" component={SqwadVideo} />
            <Route path="clips" component={SqwadClips} />
            <Route path="clip/:idVideo" component={SqwadVideo} />
            <Route path="highlights" component={SqwadHighlights} />
            <Route path="upload" component={SqwadUpload} />
            <Route path="studio" component={SqwadTest} />
            <Route path="test" component={SqwadTest} />
            <Route path="brochure" component={SqwadBrochure} />
            <Route path="college_profile" component={SqwadFamilyProfile} />
            <Route path="subscriptions" component={FamilySubscriptions} />
          </Route>
          <Route path="/sqwad/team/:idTeam" component={App_SQWAD}>
            <IndexRoute component={SqwadTeam} />
            <Route path="dashboard" component={SqwadUpload} />
            <Route path="videos" component={SqwadVideos} />
            <Route path="video/:idVideo" component={SqwadVideo} />
            <Route path="clips" component={SqwadClips} />
            <Route path="clip/:idVideo" component={SqwadVideo} />
            <Route path="highlights" component={SqwadHighlights} />
            <Route path="upload" component={SqwadUpload} />
            <Route path="studio" component={SqwadTest} />
            <Route path="test" component={SqwadTest} />
            <Route path="brochure" component={SqwadBrochure} />
            <Route path="college_profile" component={SqwadFamilyProfile} />
          </Route>
          <Route path="/roster" component={App_Roster} onEnter={requireAuth}>
            <IndexRoute component={Roster} />
            <Route path="/family/waivers" component={Waivers} />
            <Route path=':idTeam' component={RosterSeasons} />
            <Route path=':idTeam/:idSeason' component={RosterTeam} />
            <Route path='wizard/:idTeam/:idSeason' component={RosterWizard} />
            <Route path='/add_roster/new' component={RosterAddNew} />
            <Route path='/add_roster/existing' component={RosterAddExisting} />
            <Route path='/roster/uniforms' component={JerseyNumbers} />
          </Route>
          {/* Profile */}
          <Route path="/profile" component={App_Profile} onEnter={requireAuth}>
            <IndexRoute component={Profile} />
            <Route path='members/:id' component={Profile} />
            <Route path='members' component={Profile} />
            <Route path='contact' component={Contact} />
            <Route path='account' component={Credentials} />
          </Route>
          { /* Plan Upgrades */}
          <Route path="/plans" component={App_Upgrades} onEnter={requireAuth}>
            <IndexRoute component={Build_Upgrades} />
            <Route path='/plans/purchase' component={Build_Upgrades} />
            <Route path='/plans/manage' component={Soon} />
          </Route>
          { /* Registration */}
          <Route path="/registration" component={App_Registration} onEnter={requireAuth}>
            <IndexRoute component={RegistrationLeagues} />
            <Route path="/registration/team" component={TeamRegistration} />
            <Route path="/registration/user" component={UserRegistration} />
            <Route path="/registration/camp" component={CampRegistration} />
            <Route path="/registration/pending" component={PendingRegistration} />
            <Route path="/registration/checkout/:idLeague" component={Checkout} />
            <Route path="/registration/:idLeague" component={RegistrationSeasons} />
          </Route>
          { /* Payments */}
          <Route path="/payments" component={App_Payments} onEnter={requireAuth}>
            <IndexRoute component={PaymentLeagues} />
            <Route path="/payments/user" component={UserPayments} />
            <Route path="/payments/team" component={TeamPayments} />
            <Route path="/payments/:idLeague" component={PaymentSeasons} />
            { /*<Route path="/payments/team" component={ TeamPayments } />*/}
          </Route>
          { /* Selecting leagues */}
          <Route path="/admin" component={App_Admin} onEnter={requireAdmin}>
            <IndexRoute component={Leagues} />
            <Route path="/leagues" component={Leagues} />
            <Route path="/leagues/:idLeague" component={League} />
            <Route path="/validations/:idLeague" component={ValidationsSnapshot} />
            <Route path="/validations/:idLeague/user/:idUser" component={UserValidations} />
            <Route path="/validations/:idLeague/:mode" component={Validations}>
              <Route path="/validations/:idLeague/:mode/:letter" component={Validations} />
              <Route path="/validations/:idLeague/:mode/:letter/:year" component={Validations} />
            </Route>
            <Route path="/ads/:idLeague" component={Ads} />
            <Route path="/coupons/:idLeague" component={Coupons} />
            <Route path="/reports" component={Soon} />
            <Route path="/my_documents" component={Soon} />
            <Route path="/message_center/:idLeague" component={MessageCenter} />
            <Route path="/organization_settings/ads" component={Ads} />
            <Route path="/organization_settings/ads/:idCampaign" component={Ads} />
            <Route path="/organization_settings/earnings" component={OrganizationEarnings} />
            <Route path="/organization_settings/dashboard" component={OrganizationDashboard} />
            <Route path="/organization_settings/fees" component={OrganizationFees} />
            <Route path="/organization_settings/sessions" component={OrganizationSessions} />
            <Route path="/organization_settings" component={Organization} />
          </Route>
          {/* League Rankings System */}
          <Route path="/rankings/:idLeague" component={App_Admin} onEnter={requireAdmin}>
            <IndexRoute component={RankingTeams} />
          </Route>
          {/* Dob */}
          <Route path="/dob" component={App_DOB} onEnter={requireAdmin}>
            <IndexRoute component={DOB} />
            <Route path=":idLeague/:letter" component={DOB} />
          </Route>
          {/* Player Cards Validation */}
          <Route path="/pcv" component={App_PCV} onEnter={requireAdmin}>
            <IndexRoute component={PCV} />
            <Route path=":idLeague/:letter" component={PCV} />
          </Route>

          { /* Inside of a season */}
          <Route path="/season/:idSeason" component={App_Season} onEnter={requireAdmin}>
            <IndexRoute component={SeasonDashboard} />
            <Route path="/season/:idSeason/flight_manager" component={FlightManager} />
            <Route path="/season/:idSeason/manage_fields" component={FieldsManager} />
            <Route path="/season/:idSeason/schedule_master_view" component={ScheduleMasterView} />
            <Route path="/season/:idSeason/games_calendar" component={Calendar} />
            <Route path="/season/:idSeason/slots" component={Slots} />
            <Route path="/season/:idSeason/bracket_manager" component={Brackets} />
            <Route path="/season/:idSeason/points" component={Points} />
            <Route path="/season/:idSeason/teams" component={EditTeams} />
            <Route path="/season/:idSeason/set_rosters" component={SetRosters} />
            <Route path="lock_rosters" component={RosterLock} />
            <Route path="/season/:idSeason/coach_assign" component={CoachAssign} />
            <Route path="/season/:idSeason/divisions" component={Divisions} />
            <Route path="/season/:idSeason/season" component={Season} />
            <Route path="/season/:idSeason/game_cards" component={GameCards} />
            <Route path="/season/:idSeason/player/:idUser" component={UserProfile} />
            <Route path="/season/:idSeason/player/:idUser/:idTeamUserRole" component={UserProfileTeam} />
            <Route path="/season/:idSeason/scout/:idUser" component={ScoutProfile} />
            <Route path="/season/:idSeason/team/:idTeam" component={TeamProfile} />

            <Route path="/season/:idSeason/reports_auto_pay" component={AutoPayReport} />
            <Route path="/season/:idSeason/reports_registration" component={RegistrationReport} />

            <Route path="/season/:idSeason/reports_team_admins" component={TeamsAdmins} />
            <Route path="/season/:idSeason/reports_team_admins_reg" component={TeamsAdminsRegistration} />
            <Route path="/season/:idSeason/reports_team_registration" component={TeamsRegistration} />
            <Route path="/season/:idSeason/reports_team_roster" component={TeamsRoster} />
            <Route path="/season/:idSeason/reports_accounting" component={AccountingReport} />
            <Route path="/season/:idSeason/master_transactions" component={MasterTransactions} />

            <Route path="/season/:idSeason/reports_games" component={GamesReport} />
            <Route path="/season/:idSeason/reports_snapshot" component={RegistrationSnapshot} />
            <Route path="/season/:idSeason/reports_revenue" component={RevenueReports} />
            <Route path="/season/:idSeason/reports_shirts" component={ShirtsReport} />
            <Route path="/season/:idSeason/reports/pcv/:letter" component={PCVReport} />
            <Route path="/season/:idSeason/reports/pcv" component={PCVReport} />
            <Route path="/season/:idSeason/reports_donations" component={DonationsReport} />
            <Route path="/season/:idSeason/field_layouts" component={FieldLayouts} />

            <Route path="/season/:idSeason/validations/:mode" component={SeasonValidations} />
            <Route path="/season/:idSeason/validations/:mode/:letter" component={SeasonValidations} />
            <Route path="/season/:idSeason/validations/:mode/:letter/:year" component={SeasonValidations} />
            <Route path="/season/:idSeason/validations" component={SeasonValidationsMenu}>
              <Route path="/season/:idSeason/validations/profile_picture" component={ProfilePicture} />
              <Route path="/season/:idSeason/validations/profile_picture/:letter_validation" component={ProfilePicture} />
            </Route>

            <Route path="schedule_manager" component={Build_Type} />
            <Route path="schedule_manager/pairings" component={Pairings} />
            <Route path="schedule_manager/auto" component={ScheduleManager} />
            <Route path="tryouts" component={Tryouts} />
            <Route path="waitlists" component={Waitlists} />
            <Route path="registration_fees" component={RegistrationFees} />
            <Route path="custom_questions" component={CustomQuestions} />
            <Route path="print_roster_manager" component={PrintRoster} />
            <Route path="print_awards_manager" component={PrintAwards} />
            <Route path="player_cards" component={PlayerCards} />
            <Route path="say_cards" component={SayCards} />
            <Route path="travel_rosters" component={TravelRosters} />
            <Route path="score_entry" component={ScoreEntry} />
            <Route path="awards/:letter" component={Awards} />
            <Route path="awards" component={Awards} />
            <Route path="shirts/:letter" component={Shirts} />
            <Route path="shirts" component={Shirts} />
          </Route>
          {/* Public Rankings */}
          <Route path="/public/rankings" component={App_Rankings}>
            <IndexRoute component={RankingsPublic} />
          </Route>
          <Route path="/public/unsubscribe/:idFamily/:idMessage" component={App_Unsubscribe}>
            <IndexRoute component={UnsubscribeEmail} />
          </Route>
          {/*<Route path='*' exact={true} component={App_Login} />*/}
        </Router>
      </Provider>
    )
  }
}

render(<Root />, document.querySelector('#root'));

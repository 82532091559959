import React, { useMemo, useCallback } from 'react';
import { Animated } from 'react-animated-css';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router';
import _ from 'lodash';
import axios from 'axios';
import { getReadableFileSizeString, generateRandomId } from '../../helpers';
import PremiumModal from './modals/premium';
import StartModal from './modals/start';
import RemainingModal from './modals/remaining';
import ManageModal from './modals/manage';

const baseStyle = {
    flex: 1,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'video/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container" style={{ position: 'relative' }}>

            <div {...getRootProps({ style })} className="pointer">
                <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    className=" d-flex flex-row justify-content-center w-100 h-100">
                    <div style={{ width: 420, height: 420, borderRadius: 210, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
                </div>
                <input {...getInputProps()} />
                <div style={{ height: 100 }} />
                <img alt="" src="/images/icons/camera.png" />
                <p className="text-center">Drag 'n' drop some files here, or click to select files</p>
                <div style={{ border: '3px solid #2cad3c', borderRadius: 20, width: 200 }} className="p-2 text-center mt-4"><span className="green">UPLOAD VIDEOS</span></div>
                <div style={{ height: 100 }} />
            </div>

        </div>
    );
}

class Upload extends React.Component {

    state = {
        files: [],
        upgrading: false,
        promo_shown: false,
        promo: false,
        remaining: false,
        remaining_shown: false,
        manage: false,
        manage_plan: null,
        mode: 1, // [1: team, 2: family]
    }

    // Lifecycle
    componentWillMount() {
        const { setTitle, selectedTeam } = this.props;

        setTitle && setTitle({
            main: 'SQWAD Services',
            sub: (selectedTeam || { Name: '' }).Name
        });

        const { params, teamActions, familyActions } = this.props;
        teamActions && teamActions.fetchTeamSeasons && teamActions.fetchTeamSeasons(params.idTeam);
        teamActions.fetchVideos && teamActions.fetchVideos(params.idTeam);
        familyActions.getFamilySubscriptions && familyActions.getFamilySubscriptions();
        familyActions.getTeamSubscriptions && familyActions.getTeamSubscriptions(params.idTeam);
        this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(1);
        teamActions.fetchTeam(params.idTeam);
    }

    componentWillReceiveProps = nextProps => {
        const { team_subscriptions, family_subscriptions, selectedTeam, afp, atp } = nextProps;
        const { IsTeamAdmin, IsTeamOwner } = selectedTeam;
        if (atp && afp && team_subscriptions && family_subscriptions) this.setState({
            bootstrapped: true,
            atp, afp,
            current_team_plan: _.chain(team_subscriptions).filter(s => !s.Expired).sortBy('Amount').last().value(),
            current_family_plan: _.chain(family_subscriptions).filter(s => !s.Expired).sortBy('Amount').last().value(),
            wasted_family_trial: _.find(family_subscriptions, s => s.Expired && !s.Amount),
            wasted_team_trial: _.find(team_subscriptions, s => s.Expired && !s.Amount),
            mode: this.state.bootstrapped ? this.state.mode : ((IsTeamAdmin || IsTeamOwner) ? 1 : 2)
        });
    }

    componentWillUnmount = () => {
        const { teamActions } = this.props;
        teamActions.clearFiles();
    }

    toggleUpgrading = () => this.setState({ upgrading: this.state.upgrading ? false : true });

    togglePromo = () => this.setState({ promo: !this.state.promo });

    toggleRemaining = () => this.setState({ remaining: !this.state.remaining });

    upgradeFamily = () => this.setState({ upgrading: 2 });

    upgradeTeam = () => this.setState({ upgrading: 1 });

    onMode = (mode) => this.setState({ mode });

    doDrop = () => {

        const { file } = this.state;
        const { videoActions, selectedTeam, videos, family_videos, clips, highlights, atp, afp } = this.props,
            { current_family_plan, current_team_plan } = this.state,
            { IsTeamAdmin, IsTeamOwner } = selectedTeam;

        // TODO: validate if the upload is possible
        let totalbytes = _.reduce(_.map(videos, f => f.fileSize || 0), function (sum, n) {
            return sum + n;
        }, 0);
        let totalFamilyBytes = _.chain([...family_videos, ...clips, ...highlights])
            .map(f => f.fileSize || 0).reduce((a, b) => a + b, 0).value();

        if (IsTeamOwner || IsTeamAdmin) {
            if (totalbytes > (current_team_plan || _.find(atp, p => !p.Amount).StorageSpace)) {
                // TODO: alert that your quota is exceeded
                alert(`Uploading this video exceeds yout storage quota`)
                return;
            }
        } else {
            if (totalFamilyBytes > (current_family_plan || _.find(afp, p => !p.Amount).StorageSpace)) {
                // TODO: alert that your quota is exceeded
                alert(`Uploading this video exceeds yout storage quota`)
                return;
            }
        }

        videoActions.upload({
            idTeam: selectedTeam.IdTeam,
            id: generateRandomId(10),
            actualFile: file,
            progress: 0,
            cancelTokenSource: axios.CancelToken.source(),
            videoActions,
            admin: (IsTeamAdmin || IsTeamOwner)
        });

        // TODO: if the team / family does not have a plan when doing this ? enroll them in Trial
        if ((IsTeamAdmin || IsTeamOwner) && !current_team_plan) {
            // Enroll team into free trial                        
            //teamActions.subscribeToSQWADTrial(selectedTeam.IdTeam, _.find(atp, p => !p.Amount).IdPlan, {});
        } else if (!current_family_plan) {
            // Enroll family into free trial            
            //familyActions.subscribeToSQWADTrial(_.find(afp, p => !p.Amount).IdPlan, {});
        }
    }

    manage = (plan) => {
        this.setState({
            upgrading: true,
            manage_plan: plan
        });
    }

    onDrop = (file) => {

        const { current_team_plan } = this.state, { params } = this.props;
        if (!current_team_plan) {
            if (params.idTeam) {
                this.setState({
                    upgrading: true,
                    manage_plan: null,
                    file
                });
            }
            return;
        }

        /*const { promo_shown } = this.state;
        if (!promo_shown) {
            this.setState({
                promo_shown: true,
                promo: true, file
            });
            return;
        }*/
        this.setState({ file });
        this.doDrop();
    }

    onCancelUpload = (index) => {
        const { videoActions } = this.props;
        videoActions.cancel(index);
    }

    onPremiumSuccess = () => {        
        const { familyActions, params } = this.props;
        familyActions.getFamilySubscriptions && familyActions.getFamilySubscriptions();
        familyActions.getTeamSubscriptions && familyActions.getTeamSubscriptions(params.idTeam);
    }

    render() {

        const { files, videos, family_videos, selectedTeam = {}, params } = this.props,
            { mode, bootstrapped, afp, atp, current_team_plan, current_family_plan, upgrading, promo, remaining, manage } = this.state;

        let wefiles = files;
        let totalbytes = _.reduce(_.map(videos, f => f.fileSize || 0), (a, b) => a + b, 0);

        const { IsTeamAdmin, IsTeamOwner } = selectedTeam;
        let current = (IsTeamAdmin || IsTeamOwner) ?
            current_team_plan : current_family_plan;        

        return <section>
            <div className="d-flex flex-row">
                <div className="w-100 pt-4 pr-2 pb-4 pl-4 d-flex flex-column">
                    <div className="p-2 d-flex flex-row" style={{ height: 60 }}>
                        <h2><b>Welcome!</b> Upload your team videos here</h2>
                    </div>
                    <div className="tile shadow-box card pt-4 pb-4 mt-4">
                        <StyledDropzone onDrop={this.onDrop} />
                        <span className="font-8 gray text-center m-4">By submitting your videos, you acknowledge that you agree to Sports Logic / SQWAD Terms of Service </span>
                    </div>
                </div>

                {bootstrapped && videos && family_videos && <Animated animationIn="slideInRight" animationInDelay={50} className=" w-100 pt-4 pr-4 pb-4 pl-2 d-flex flex-column">
                    <div className="d-flex flex-row">
                        <div className="w-25" />
                        <div className="tile shadow-box card p-2 d-flex flex-row w-75" style={{ height: 60 }}>
                            <div style={{ width: 30, height: 30, borderRadius: 15, border: '1px solid black', background: `url("https://api.sportslogic.net/api/v4/teams/image/decode/${params.idTeam}.png") no-repeat center center`, backgroundSize: 'cover' }} className="align-self-center" />
                            {selectedTeam && <span className="black toBold align-self-center ml-2">{selectedTeam.TeamName || selectedTeam.Name}</span>}
                            <Link to="/sqwad" className="align-self-center ml-auto blue mr-2">Change</Link>
                        </div>
                    </div>
                    <div className="tile shadow-box card mt-4">
                        <div className="w-100 h-100 p-4">

                            <h2 className="text-center">Here are your metrics</h2>
                            <div className="metric text-center white mt-4 hoverable">
                                <Link to={`/sqwad/team/${params.idTeam}/videos`} className="white">
                                    <div style={{ height: 25 }} />
                                    <h1>{videos.length + family_videos.length}</h1>
                                    <h4>Video{(videos.length + family_videos.length) > 1 ? 's' : ''}</h4>
                                    <div style={{ height: 25 }} />
                                </Link>
                            </div>
                            <div className="metric text-center white mt-2" style={{ overflow: 'hidden' }}>

                                <div style={{ height: 25 }} />
                                {/* TEAM METRICS */}
                                {mode === 1 && this.props.disclaimer && (IsTeamAdmin || IsTeamOwner) && <div>
                                    <h1 className={totalbytes > (current_team_plan || _.find(atp, p => !p.Amount).StorageSpace) ? 'text-danger' : ''}>{getReadableFileSizeString(totalbytes)} out of {(current_team_plan || _.find(atp, p => !p.Amount) || { StorageLabel: '?GB' }).StorageLabel}</h1>
                                    <h4>Space Available</h4>
                                    <div style={{ height: 10 }} />
                                    <div style={{ border: '3px solid #2cad3c', backgroundColor: '#2cad3c', borderRadius: 20, width: 150 }} className="m-auto p-2 text-center pointer" onClick={() => current_team_plan ? this.manage(current_team_plan) : this.upgradeTeam()}><span className="white">{(current_team_plan) ? 'MANAGE' : 'UPGRADE'}</span></div>
                                </div>
                                }
                                <div style={{ height: 25 }} />
                            </div>
                        </div>
                    </div>
                </Animated>}
            </div>

            <div style={{ position: "fixed", right: '4em', bottom: 20, left: '13em', height: 'auto' }}>
                {wefiles.map((file, i) => <div key={i} className={`tile card p-2 mt-2 d-flex flex-row ${file.canceled ? 'bg-danger' : 'bg-gray'}`} style={{ position: 'relative', height: 60, borderRadius: 30, overflow: "hidden" }}>
                    <div className={`${file.canceled ? 'bg-danger' : 'bg-success'}`} style={{ position: 'absolute', left: 0, width: `${file.progress}%`, top: 0, bottom: 0 }} />
                    {file.progress < 100 && <span className="ml-4 w-100 align-self-center font-16 white" style={{ zIndex: 1 }}>{file.actualFile.name}</span>}
                    {file.progress < 100 && !file.canceled && <span className="w-100 text-center align-self-center font-30 white" style={{ zIndex: 1 }}>{file.progress}%</span>}
                    {file.progress < 100 && !file.canceled && <span className="w-100 text-right mr-4 align-self-center white pointer" style={{ zIndex: 1 }} onClick={() => this.onCancelUpload(i)}>Cancel</span>}
                    {file.progress < 100 && file.canceled && <span className="w-100 text-right mr-4 align-self-center white" style={{ zIndex: 1 }}>Canceled</span>}

                    {file.progress >= 100 && <span className="ml-4 align-self-center white" style={{ zIndex: 1 }}><i className="far fa-check-circle" /> Video uploaded successfully. You can see it in the video section</span>}
                    {file.progress >= 100 && <Link className="ml-auto mr-4 align-self-center white pl-4 pr-4" style={{ zIndex: 1, border: '1px solid white', height: 30, borderRadius: 15 }} to={`/sqwad/team/${params.idTeam}/videos`}>Go to Videos</Link>}
                </div>)}
            </div>

            {/* BOTTOM GAP */}
            {wefiles.map((a, i) => <div key={i} style={{ height: 80 }} />)}

            {upgrading && <PremiumModal
                toggle={() => this.setState({ upgrading: false })}
                isOpen={upgrading ? true : false}
                selectedTeam={selectedTeam}
                disclaimer={this.props.disclaimer}
                family_actions={this.props.familyActions}
                success={this.onPremiumSuccess}
                target={this.state.manage_plan || (upgrading ? _.find(atp, p => p.Amount) : _.find(afp, p => p.Amount))} />}

            {promo && <StartModal {...this.props} toggle={this.togglePromo} isOpen={promo} plans={(IsTeamAdmin || IsTeamOwner) ? atp : afp} upgrade={this.toggleUpgrading} start={this.doDrop} />}

            {remaining && current && !wefiles.length && <RemainingModal {...this.props} toggle={this.toggleRemaining} isOpen={remaining}
                current={current} upgrade={this.toggleUpgrading} plans={(IsTeamAdmin || IsTeamOwner) ? atp : afp} />}

            {manage && <ManageModal {...this.props} toggle={() => this.setState({ manage: false })} isOpen={manage} target={this.state.manage_plan} />}

        </section>
    }
}

export default Upload;
